.call {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.manager-block {
  margin-bottom: 25px;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #EDEDED;
}

.manager-block__inner {
  padding: 5px 45px;
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f7f7f7;
}

.manager-block__left {
  display: flex;
  align-items: center;
}

.manager-block__photo {
  margin-right: 15px;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.manager-block__text {
  font-family: "Roboto";
  font-size: 14px;

  p {
    margin: 0;
    margin-bottom: 5px;
  }

  span {
    color: rgb(64, 64, 64);
  }
}

.refresh {
  position: relative;
  display: inline-block;
  width: 27px;
  height: 25px;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('../images/refrech.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.call__table {
  width: 100%;
}

.table {
  width: 100%;
  font-size: 14px;
  font-family: "Helvetica";
  color: rgb(38, 38, 38);
  font-size: 14px;
}

.table__row {
  border-bottom: 1px solid #EDEDED;

  a {
    color: inherit;
  }
}

.table__td {
  padding: 30px 25px;
}

.table__light {
  color: rgb(120, 127, 129);
}

.table__success,
.table__filed {
  display: inline-block;
  position: relative;

  i {
    display: inline-block;
    position: relative;
    width: 10px;
    height: 10px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }
}

.table__success {
  color: #7b9438;

  i:before  {
    background-image: url(../images/succes.png);
  }
}

.table__finished {
  color: #787f81;
}

.table__filed {
  color: #945438;

  i:before  {
    background-image: url(../images/failed.png);
  }
}
