.button {
	border-radius: 4px;
	overflow: hidden;
}

.button--light-blue {
	background: #006ede;
	color: #fff;
	font-size: 14px;

	.arrow-left {
		background: #0066d3;
	}

	&:hover {
		background: #0066d3;
	}
}

.button--red {
	background-color: #ff2c4c !important;
	color: #fff;
}

.button--bordered-red {
	border: 1px solid #ff2c4c;
	color: #ff2c4c;

	&:hover {
		background-color: #ff2c4c !important;
		color: #fff;
	}
}

.button--124 {
	width: 124px;
	height: 40px;
	line-height: 40px;
}

.arrow-left {
  color: #fff;
  position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	&:before {
		content: '';
		position: absolute;
		z-index: 1;
		width: 18px;
		height: 18px;
		left: 15px;
		border-left: solid 1px currentColor;
		border-top: solid 1px currentColor;
		transform: rotate(-45deg);
	}

	// &:after {
	// 	content: '';
	// 	position: absolute;
	// 	left: 6px;
	// 	width: 8px;
	// 	height: 8px;
	// 	border-bottom: solid 1px currentColor;
	// 	border-right: solid 1px currentColor;
	// 	transform: rotate(-45deg);
	// }
}



