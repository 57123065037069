.auth-page {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	background: #1E232F;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow-y: auto;
	button.button {
		display: block;
		border: none;
	}
	.button {
		border-radius: 4px;
		min-width: 157px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-decoration: none;
		text-align: center;
		outline: none;
		background: none;
		cursor: pointer;
		user-select: none;
		padding: 0 20px;
		transition: .2s ease;
		i {
			display: inline-block;
			flex: none;
			margin-right: 15px;
		}
		&.red {
			background: #FF3046;
			color: #fff;
			font-size: 16px;
			&:hover {
				background: darken(#FF3046, 8%);
			}
			&:active {
				background: darken(#FF3046, 15%);
			}
		}
		&.red-bordered {
			border: 1px solid #FF3046;
			color: #FF3046;
			font-size: 16px;
			&:hover {
				background: #FF3046;
				color: #fff;
			}
			&:active {
				background: darken(#FF3046, 8%);
				color: #fff;
			}
		}
		&.yellow {
			background: #F4CF00;
			color: #1f1f1f;
			font-size: 16px;
			&:hover {
				background: darken(#F4CF00, 3%);
			}
			&:active {
				background: darken(#F4CF00, 6%);
			}
		}
		&.grey {
			background: #ECECEC;
			color: #1f1f1f;
			font-size: 16px;
			&:hover {
				background: darken(#ECECEC, 3%);
			}
			&:active {
				background: darken(#ECECEC, 6%);
			}
		}
	}
	.auth-wrapper {
		width: 460px;
		.auth-block {
			background: #fff;
			.logo {
				@include sprite($logo2);
				border-bottom: 1px solid #CCCCCC;
			}
			.body {
				padding: 30px 45px;
				.title {
					text-align: center;
					font-size: 22px;
					color: #3b3b3b;
					margin-bottom: 30px;
				}
				form {
					.form-group {
						margin-bottom: 20px;
						label {
							display: block;
							font-size: 14px;
							font-weight: 300;
							margin-bottom: 8px;
						}
						input[type=text] {
							width: 100%;
							border-radius: 3px;
							height: 40px;
							border: 1px solid #C8C8C8;
							outline: none;
							padding: 0 15px;
							font-size: 14px;
							color: #000;
							@include input-placeholder {
								color: #8F8F8F
							}
						}
						input[type=password] {
							width: 100%;
							border-radius: 3px;
							height: 40px;
							border: 1px solid #C8C8C8;
							outline: none;
							padding: 0 15px;
							font-size: 14px;
							color: #000;
							@include input-placeholder {
								color: #8F8F8F
							}
						}
					}
					.under-form {
						display: flex;
						justify-content: space-between;
						align-items: center;
						.checkbox {
							color: #3b3b3b;
							font-size: 14px;
							font-weight: 300;
							label {
								input {
									position: relative;
									top: 2px;
								}
							}
						}
						a {
							font-size: 14px;
							color: #3b3b3b;
							font-weight: 300;
							&:hover {
								text-decoration: none;
							}
						}
					}
					.button-wrapper {
						display: flex;
						align-items: center;
						padding-top: 30px;
						&.right {
							justify-content: flex-end;
						}
						&.between {
							justify-content: space-between;
							.link {
								font-size: 14px;
								color: #3b3b3b;
								font-weight: 300;
								&:hover {
									text-decoration: none;
								}
							}
						}
					}
				}
			}
		}
		.auth-info {
			text-align: center;
			font-size: 14px;
			color: #fff;
			padding: 30px 0;
		}
	}
}