.house {
	width: 100%;
	height: 100%;
}

.house__wrapper {
	padding: 140px 90px;
}

.house__block {
	display: flex;
	justify-content: space-between;
	width: 100%;
  min-height: 566px;
  background-color: #ffffff;
	box-shadow: 0 0 9px 0 rgba(62, 62, 62, 0.1);

	&-left {
		max-width: 660px;
		border-right: 1px solid #ededed;
	}

	&-right {
		width: 100%;
		max-width: 770px;

	}
}

.house__form {
	padding-top: 35px;

	.form__group {
		padding-right: 30px;
		width: 365px;
	}

	.form__select {
		margin-top: 23px;
	}
}

.house__gallery {
	padding: 30px;
}

.gallery {
	padding: 10px 0;
	font-family: Roboto;
  font-size: 14px;
}

.gallery__header {
	margin-bottom: 35px;
	display: flex;
	justify-content: space-between;
}

.gallery__title {
  font-weight: 300;
  color: #3b3b3b;
}

.gallery__add {
	width: 173px;
	height: 30px;
	line-height: 27px;
  border-radius: 4px;
}

.gallery__add.button-red {
	color: #000000;
}

.gallery__list {
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.gallery__item {
	margin-right: 14px;
	margin-bottom: 20px;
	list-style-type: none;
	width: 185px;
  height: 207px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 3px 0 rgba(15, 16, 16, 0.07);
}

.photo {
	position: relative;
}

.photo__controlls {
	padding: 10px;
	display: flex;
	align-items: center;
}

.photo__rotate {
	display: inline-block;
	width: 15px;
	height: 15px;
	background-image: url("../images/rotate.svg");
	background-repeat: no-repeat;
	cursor: pointer;
}

.photo__rotate-right {
	margin-right: 10px;
	transform: scale(-1, 1);
}

.photo__rotate-delete {
	position: relative;
	margin-left: auto;
	width: 15px;
	height: 15px;
	.remove.icon {
		cursor: pointer;
		color: #000;
	}

	.remove.icon:before {
		content: '';
		position: absolute;
		top: 8px;
		width: 16px;
		height: 1px;
		background-color: currentColor;
		transform: rotate(45deg);
	}
	.remove.icon:after {
		content: '';
		position: absolute;
		top: 8px;
		width: 16px;
		height: 1px;
		background-color: currentColor;
		transform: rotate(-45deg);
	}

	.remove.icon:hover:after,
	.remove.icon:hover:before {
		background-color: red;
	}
}

.photo__wrapper {
	position: relative;
	width: 185px;
	height: 135px;
	cursor: pointer;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&:after {
		content: '';
		position: absolute; top: 0; right: 0; bottom: 0; left: 0;
		width: 100%;
		height: 100%;
		transition: all .5s ease;
	}
	&:hover:after {
		background: rgba(#1f1f1f, .5);
	}

	&:hover .photo__icon {
		opacity: 1;
		visibility: visible;
	}
}

.photo__icon {
	position: absolute;
	z-index: 10;
	opacity: 0;
	visibility: hidden;
	top: 0; right: 0; bottom: 0; left: 0;
	margin: auto;
	width: 26px;
  height: 26px;
	background: url('../images/search.svg') no-repeat;
	transition: all .5s ease;
}

.photo__public {
	padding: 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	label {
		font-family: Roboto;
		font-size: 14px;
		color: #3c3c3c;
		cursor: pointer;
	}

	input {
		display: none;
	}

	.fake-chackbox {
		position: relative;
		display: inline-block;
		width: 22px;
		height: 22px;
		border-radius: 3px;
		background-color: #ffffff;
		border: solid 1px #d5d5d5;

		&::after {
			content: "";
			position: absolute;
			margin-left: 3px;
			margin-top: 3px;
			width: 14px;
			height: 8px;
			border-bottom: solid 1px #3d7dde;
			border-left: solid 1px #3d7dde;
			transform: rotate(-45deg);
			display: none;
		}
	}

	input:checked + .fake-chackbox::after {
		display: block;
	}
}

@media  screen and (max-width: 1500px) {
	.house__block {
		flex-direction: column;
	}

	.house__block-left, .house__block-right {
		max-width: 100%;
	}
}