@font-face {
	font-family: "MyriadPro-Regular";
	src: url("../fonts/MyriadPro-Regular.otf");
}

.new-building {
	width: 100%;

	.head {
		padding-top: 15px;
		display: flex;
		align-items: center;
		margin-bottom: 30px;

		select {
			margin-left: 20px;
		}
	}

	.content-selectors {
		padding: 0 35px;
    display: flex;
		border-bottom: 2px solid #F0F0F0;
	}

	.column {
		max-width: 500px;
	}

	.apartments-view-page {
		padding: 0 25px;
	}

	.apartments-view-page .page-common .photos-module.open,
	.apartments-view-page .page-common .general-module.open  {
		flex-direction: column;
		align-items: flex-start;
	}

	.photos-list {
		display: grid;
    grid-template-columns: repeat(auto-fill, 185px);
    grid-gap: 15px;
	}

	.content-selector {
		position: relative;
		margin-right: 55px;
		font-size: 14px;
		font-family: "MyriadPro-Regular";
		line-height: 1.857;
		text-decoration: underline;
		color: rgb(52, 94, 177);

		&:before {
			content: "";
			position: absolute;
			bottom: -2px;
			right: 0;
			left: 0;
			border-bottom: 3px solid transparent;
		}

		&.active {
			color: rgb(94, 94, 94);
			text-decoration: none;
			&:before {
				border-color: #3E50AA;
			}

			&:after {
				content: "";
				position: absolute;
				width: 0;
				height: 0;
				box-sizing: border-box;
				border: 5px solid black;
				border-color: transparent transparent #3E50AA #3E50AA;
				transform-origin: 0 0;
				transform: rotate(-45deg);
				top: 100%;
				right: 50%;
				margin-left: calc(14.14/-2px);
			}
		}
	}
}

.new-building__subheader {
	margin-bottom: 30px;
	padding: 0 35px;
	display: flex;
	align-items: center;
	height: 60px;
	box-shadow: 0px 3px 5px 0px rgba(66, 66, 66, 0.11);
}

.new-building__back {
	display: flex;
	align-items: center;

	.arrow-left {
		width: 35px;
		margin-right: 20px;
	}
}


.apartment_types {
	width: 100%;
	font-family: "MyriadPro-Regular";

	ul {
		margin: 0;
		padding: 0;
		li {
			height: 60px;
			display: flex;
			align-items: center;
			border: 1px solid rgb(246, 246, 246);

			.button {
				margin-left: auto;
			}

			.name {
				width: 24%;
			}
		}
	}

	&.apartment_types_3 {
		ul li {
			span {
				margin-right: 70px;
			}
		}
	}
}

.add_flat_modal.modal.open {
	visibility: visible;
	opacity: 1;
}

.add_flat_modal.modal {
	display: flex;
	justify-content: center;
	align-items: center;
	overflow-y: auto;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.7);
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s ease .3s, opacity .3s ease;

	.modal__dialog {
    max-width: 500px;
    height: 500px;
	}

	.form-groups {
		margin-bottom: 30px;
		display: flex;
		flex-direction: column;

		label {
			margin-bottom: 15px;
		}
	}
}

.modal__content {

}