.reviews {
  width: 100%;
}

.reviews__add {
  width: 100%;
  height: 179px;
  display: flex;
}

.reviews__author {
  width: 54px;
  height: 53px;
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.reviews__form {
  margin-left: 30px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  background-color: #fff;
}

.form__button {
  padding: 0 35px;
  height: 43px;
  border:0;
  border-radius: 4px;
  background-color: #455ead;
  line-height: 42px;

  color: #fff;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  align-self: flex-end;
  transition: all .25s ease;
  text-align: center;

  &:hover {
    background-color: lighten(#455ead, 10%)
  }
}

.form__textare {
  height: 70%;
  padding: 5px;
  resize: none;
  border: 0;
  outline: none;
}

.reviews__list {
  padding: 30px;
  background-color: #fcfcfc;
}

.reviews__item {
  padding: 35px;
  width: 100%;
  background-color: #fff;
  list-style-type: none;
}

.reviews__item-head {
  margin-bottom: 30px;
  display: flex;
}

.reviews__info {
  margin-left: 10px;
}

.reviews__name {
  color: #2672b2;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  text-decoration: underline;
}

.reviews__created {
  color: #818181;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 300;
}

.reviews__text {
  color: #1c1c1c;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  line-height: 26px;
}