@font-face {
	font-family: 'Helvetica';
	src: url('../fonts/HelveticaNeue.ttf');
}
.applications {
  width: 100%;
}

.applications__header {
  padding:0 35px;
  display: flex;
  justify-content: flex-end;
  .button {
    width: 190px;
  }
}

.table__button {
  margin-bottom: 5px;
  display: block;
  border-radius: 3px;
  height: 26px;
  line-height: 26px;
  width: 191px;
}

.button-bordered.button-red {
  border: 1px solid #f8304c;
  color: #f8304c;

  &:hover {
    background-color: #f8304c;
    color: #fff;
  }
}

.confirmation__row,
.confirmation__col {
  display: flex;
  font-size: 14px;
  font-family: "Roboto";

  span:first-child {
    color: #3b3b3b;
  }

  span:last-child,
  span:last-child a {
    color: #282727;
    font-weight: 500;
  }
}

.confirmation__col {
  display: flex;
  flex-direction: column;

  span:last-child {
    font-weight: normal;
    color: #888888;
  }
}

.confirmation__button {
  width: 175px;
  background-color: #7b9438;
}

.notify {
  display: none;
  position: fixed;
  z-index: 1010;
  top: 0;
  right: 0;
  width: 340px;
  min-height: 100%;
  background-color: #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.22);
  font-family: 'Helvetica';
}

.notify__content {
  padding:25px 0;
  display: flex;
  flex-direction: column;
}

.notify__header {
  padding: 25px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
  color: #4d4d4d;
}

.notify__title {
  font-size: 20px;
}

.notify__close {
  display: flex;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
}

.notify__icon-close {
  position: relative;
  margin-left: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border:1px solid #5a5a5a;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 15px;
    height: 1px;
    background-color: #5a5a5a;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.notify__body {
  padding: 25px;
}

.notify__list {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.notify__item  {
  margin-bottom: 10px;
  padding: 15px 20px;
  width: 100%;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  font-size: 14px;
  text-align: right;
  color: #70757c;

  a {
    color: #4d4d4d;
    color: inherit;
    font-weight: 700;
  }

  &-info {
    background-color: #d1e2ff;
  }

  &-fail {
    background-color: #ffdfd1;
  }

  &-success {
    background-color: #efffe6;
  }
}