main.mortgage-calculator {
	.apartments-view-page .page-common .general-module .column {
		display: grid;
		max-width: 1024px;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 12px;
		padding: 12px;
		.box {
			margin: 0;
			.box-body.calc{
				.form-group {
					label {
						width: 150px;
					}
				}
				.button {
					width: 170px;
					margin-left: auto;
				}
			}
			.box-body {
				.form-group .text {
					font-size: 14px;
					color: #3e3e3e;
				}
			}
		}
		.payments {
			grid-column: span 2;
			.box-body table{
				width: 100%;
				tr {
					border-bottom: 2px solid #ebebeb;
					font-size: 14px;
					&:first-child,&:last-child {
						border-bottom: none;
					}
					th {
						text-align: left;
						font-weight: 400;
						color: #b1b1b1;
						padding: 10px 5px;
					}
					td {
						text-align: left;
						padding: 10px 5px;
					}
				}
			}
		}
	}
}