@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700,300,900);

@mixin input-placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}

html,
body {
    min-width: 980px;
    width: 100%;
    display: flex;
    flex: 1 1 auto;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100vh;
}

html {
	min-height: 100%;
}
body {
	font-family: "Roboto", sans-serif, Arial;
	font-size: 100%;
}

input,
textarea {
    @include input-placeholder {
    	color: #000;
    	font-weight: 300;
    }
}

@import 'sprite';

#small-dialog {
	background: white;
	padding: 20px 30px;
	text-align: left;
	max-width: 400px;
	margin: 40px auto;
	position: relative;
}

.container {
    overflow: hidden;
    display: flex;
    flex: 1 1 auto;
}

nav.nav-panel {
    position: relative;
    flex: 1 1 auto;
    max-width: 250px;
    min-width: 250px;
    background: #1E232F;
    overflow: hidden;
    transition: all .24s ease;

    .logo {
        margin-left: auto;
        @include sprite($logo);
        border-bottom: 1px solid #008AEB;
        border-right: 1px solid #EDEDED;
        width: 210px;
    }
    .menu {
        a {
            color: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-decoration: none;
            font-weight: 300;
            padding: 12px 24px;
            &:hover {
                background: #171616;
                .num {
                    background: #D02A3B;
                }
            }
            &.active {
                background: #FF3046;
                .num {
                    background: #D02A3B;
                }
            }
            &.disabled {
                pointer-events: none;
                cursor: default;
                opacity: 0.5;
            }
            .num {
                font-size: 14px;
                border-radius: 25px;
                height: 18px;
                padding: 0 5px;
                min-width: 32px;
                margin-left: 20px;
                background: #121821;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .accordion {
            background-color: rgb(30, 35, 47);
            color: #fff;
            cursor: pointer;
            padding: 18px;
            width: 100%;
            text-align: left;
            border: none;
            outline: none;
            transition: 0.4s;
        }

        /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
        .active, .accordion:hover {
            background-color: #171616;
        }

        /* Style the accordion panel. Note: hidden by default */
        .accordion-panel {
            padding: 0 18px;
            //background-color: white;
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.2s ease-out;
        }
    }
}

main {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
    header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // border-width: 0px 0px 1px 1px;
        // border-style: solid;
        // border-color: #EDEDED;
        border-bottom: 1px solid #EDEDED;
        flex: none;
        padding: 0 30px;
        padding-left: 60px;
        .title {
            font-size: 20px;
            color: #444c63;
            flex: 1 1 1px;
        }
        .controls {
            display: flex;
            align-items: center;
            .control-block {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                padding: 0 20px;
                height: 59px;
                position: relative;
                &:hover {
                    background: #F8F8F8;
                }
                i {
                    display: block;
                    flex: none;
                }
                .num {
                    position: absolute;
                    left: 8px;
                    bottom: 8px;
                    height: 21px;
                    border-radius: 30px;
                    background: #3E50AB;
                    font-size: 11px;
                    font-weight: 300;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    padding: 0 5px;
                    min-width: 21px;
                    color: #fff;
                    font-style: normal;
                }
                &:nth-child(1) {
                    i {
                        @include sprite($ic3)
                    }
                }
                &:nth-child(2) {
                    i {
                        @include sprite($ic4)
                    }
                }
                &:nth-child(3) {
                    i {
                        @include sprite($ic5)
                    }
                }
            }
        }
        .personal-info {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex: 1 1 1px;
            .text {
                padding-right: 20px;
                .name {
                    font-size: 14px;
                    color: #2d2c2c;
                }
                a {
                    font-size: 14px;
                    color: #a0a0a0;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            .ava {
                border: 1px solid rgb(165, 165, 165);
                border-radius: 100%;
                width: 45px;
                height: 45px;
                overflow: hidden;
                img {
                    display: block;
                    width: 100%;
                }
            }
        }
    }
    .main-container {
        position: relative;
        flex: 1 1 auto;
        display: flex;
        overflow-y: auto;
    }
}
ul.pagination{
    list-style: none;
    display: flex;
    li {
        cursor: pointer;
        height: 17px;
        a {
            text-decoration: none;
            color: #262626;
        }
        &:hover:not(.disabled):not(.active){
            font-size: 14px;
            border-radius: 25px;
            background-color: #DFDFDF;
        }
        &.active{
            cursor: default;
            background-color: #D02A3B;
            font-size: 14px;
            border-radius: 25px;
            a {
                color: #fff;
                cursor: default;
            }
        }
        &.next.disabled{
            cursor: default;
            color: #989898;
        }
        &.prev.disabled{
            cursor: default;
            color: #989898;
        }
        min-width: 40px;
        text-align: center;
        /*padding-right: 7px;
        padding-left: 7px;
        margin-left: 3px;
        margin-right: 3px;*/
    }
}


.notifications-container {
    background-color: #fff;
    box-shadow: 0px 3px 8px 0px rgba(114, 114, 114, 0.18);
    position: absolute;
    right: 0;
    top: 60px;
    width: 362px;
    height: calc(100vh - 60px);
    z-index: 100;
    display: flex;
    flex-direction: column;
    transform: translateX(100%);
    transition: .4s ease;
    &.open {
        transform: none;
    }
    .head {
        display: flex;
        justify-content: flex-end;
        padding: 15px;
        padding-bottom: 0;
        margin-bottom: 15px;
        flex: none;
        .clear-notifications {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 35px;
            padding-right: 8px;
            font-size: 13px;
            color: #1f1f1f;
            font-weight: 300;
            border-radius: 6px;
            background-color: rgb(243, 243, 243);
            width: 168px;
            height: 31px;
            cursor: pointer;
            &:hover {
                background-color: darken(rgb(243, 243, 243), 5%);
            }
            &:active {
                background-color: darken(rgb(243, 243, 243), 10%);
            }
            i {
                display: block;
                flex: none;
                svg {
                    width: 14px;
                    height: 19px;
                }
            }
        }
    }
    .notifications-list {
        padding: 0 15px 15px;
        flex: 1 1 auto;
        overflow-y: auto;
        .notification-item {
            border-radius: 6px;
            background: #FCFCFC;
            padding: 20px;
            font-size: 13px;
            line-height: 1.4em;
            color: #1f1f1f;
            font-weight: 300;
            margin: 15px 0;
            position: relative;
            &:hover {
                background: darken(#FCFCFC, 5%);
            }
            .remove-notification {
                position: absolute;
                right: 0;
                top: 0;
                cursor: pointer;
                opacity: .5;
                padding: 5px 10px;
                &:hover {
                    opacity: 1;
                }
            }
            a {
                color: #4172f0;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.notifications-module {
    width: 410px;
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 0 25px 0;
    z-index: 102;
    .hide-wrapper {
        display: flex;
        justify-content: flex-end;
        .hide-all-notifications {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 13px;
            border-radius: 6px;
            box-shadow: 0px 3px 8px 0px rgba(114, 114, 114, 0.18);
            background: #fff;
            color: #1f1f1f;
            font-weight: 300;
            cursor: pointer;
            width: 140px;
            height: 30px;
            padding: 0 10px 0 30px;
            &.hide {
                display: none;
            }
            &:hover {
                background: darken(#fff, 1%);
            }
        }
    }
    .notifications-list {
        margin: -15px 0;
        .notification-item {
            border-radius: 6px;
            background: #fff;
            padding: 20px;
            padding-top: 25px;
            font-size: 13px;
            line-height: 1.4em;
            color: #1f1f1f;
            font-weight: 300;
            margin: 25px 0;
            position: relative;
            box-shadow: 0px 3px 8px 0px rgba(114, 114, 114, 0.18);
            cursor: pointer;
            &:hover {
                background: darken(#fff, 1%);
            }
            .remove-notification {
                position: absolute;
                right: 0;
                top: 0;
                cursor: pointer;
                opacity: .5;
                padding: 5px 10px;
                &:hover {
                    opacity: 1;
                }
            }
            a {
                color: #4172f0;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.common-modal {
    position: fixed;
    left: 250px;
    top: 60px;
    height: calc(100vh - 60px);
    width: calc(100vw - 250px);
    background: rgba(#0E0E0E, .13);
    z-index: 101;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease, visibility 0s ease 0.3s;
    &.open {
        opacity: 1;
        visibility: visible;
        transition: opacity .3s ease;
        .modal {
            transform: none;
        }
    }
    .modal {
        background-color: #fff;
        box-shadow: 0px 4px 9px 0px rgba(62, 62, 62, 0.11);
        width: 448px;
        margin: auto;
        transform: scale(0.8);
        transition: .3s ease;
        .change-client-form {

        }
        .title {
            color: #222222;
            font-weight: 300;
            font-size: 16px;
            padding: 20px 18px;
            border-bottom: 1px solid #F7F7F7;
        }
        .body {
            padding: 20px 18px;
            .form-group {
                label {
                    display: block;
                    margin-bottom: 10px;
                    font-size: 14px;
                    font-weight: 300;
                    color: #9f9f9f;
                }
                input,
                select,
                textarea {
                    width: 100%;
                    border-style: solid;
                    border-width: 1px;
                    border-color: rgb(227, 227, 227);
                    border-radius: 4px;
                    height: 45px;
                    font-size: 14px;
                    font-weight: 300;
                    color: #242424;
                    padding: 0 15px;
                    outline: none;
                }
                textarea{
                    height: 90px;
                }
                select {
                    padding: 0 10px;
                }
            }
        }
        .button-wrapper {
            padding: 0 20px 18px 20px;
            display: flex;
            justify-content: flex-end;
            button {
                border-radius: 4px;
                background-color: #4CBF63;
                width: 208px;
                height: 45px;
                color: #fff;
                font-size: 14px;
                font-weight: 300;
                cursor: pointer;
                outline: none;
                border: none;
                &:hover {
                    background: darken(#4CBF63, 3%);
                }
                &:active {
                    background: darken(#4CBF63, 6%);
                }
            }
        }
    }
}
.ui-autocomplete{
    z-index: 999999999999;
}

.found-total {
    padding-right: 100px;
    padding-bottom: 30px;
}

.found-inner {
    width: 190px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    background-color: #fff;
    font-size: 14px;
    color: red;
    text-align: center;
}

.nav-panel.close {
    margin-left: -250px;
}

.nav-panel__header {
    background-color: #fff;
}

.burger {
    position: absolute;
    top: 20px;
    left: 15px;
    width: 30px;
    z-index: 1000;
    cursor: pointer;
}

.burger span {
    display: block;
    width: 100%;
    height: 3px;
    margin-bottom: 3px;
    position: relative;
    top: 0;
    transition: all ease-in-out 0.4s;
    background: #D02A3B;
}

.burger.open span:nth-child(1) {
    transform: rotate(-45deg);
    transform-origin: center center;
    top: 6px;
}

.burger.open span:nth-child(2) {
    width: 0;
    opacity: 0;
}

.burger.open span:nth-child(3) {
    transform: rotate(45deg);
    transform-origin: center center;
    top: -6px;
}