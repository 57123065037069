.submodule {
  width: 100%;
  background-color: #fcfcfc;
}

.submodule__list {
  margin: 0;
  padding: 0;
  padding-top: 30px;


  li {
    min-height: 73px;
    padding: 0 35px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #e8e8e8;

    &:last-child {
      border-bottom: none;
    }
  }
}

.submodule__date {
  margin-right: 40px;
  display: inline-block;
  min-width: 100px;
  max-width: 100px;
  color: #818181;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
}

.changes__ava {
  display: inline-block;
  vertical-align: middle;
  width: 54px;
  height: 53px;
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.changes__who {
  display: inline-block;
  jkwidth: 285px;
}

.changes__name {
  margin: 0 10px;
  display: inline-block;
  vertical-align: middle;
  color: #2672b2;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  text-decoration: underline;
}

.submodule__text {
  color: #1c1c1c;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
}