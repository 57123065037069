.apartment__modal {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s ease .3s, opacity .3s ease;

  &.open {
    visibility: visible;
    opacity: 1;
    transition: opacity .3s ease;
  }

  ul li {
    list-style-type: none;
  }

  .button__wrap {
    margin-top: 10px ;
    width: 100%;
    text-align: center;
  }

  .button {
    margin: auto;
    display: inline-block;
    padding: 0 30px;
    height: 28px;
    border-radius: 3px;
    background-color: #455ead;
    line-height: 28px;
    color: #fff;

    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    text-decoration: none;
    transition: all .3s ease;

    &:hover {
      background-color: lighten(#455ead, 10%);
    }
  }
}

.modal__dialog {
  margin-top: 60px;
  width: 100%;
  max-width: 667px;
  height: 881px;
}

.modal__content {
  padding: 50px;
  position: relative;

  display: flex;
  flex-direction: column;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  box-shadow: 0 9px 25px rgba(62, 62, 62, 0.06);

  .close {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 20px;
    text-decoration: none;
  }

  button.close {
    border: 0;
    background: none;
    -webkit-appearance: none;
    -o-appearance: none;
    outline: none;
  }
}

.modal__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal__body {
  position: relative;
  flex: 1 1 auto;
}

.apartment__slider {
  position: relative;
  margin-bottom: 35px;
}

.slider__loupe {
  position: absolute;
  top: 15px;
  right: 15px;
  display: inline-block;
  width: 30px;
  height: 30px;
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.apartment__control {
  position: absolute;
  top: calc(50% - 15px);
  border: 0;
  background: none;
  -webkit-appearance: none;
  -o-appearance: none;
  outline: none;

    &--right {
      right: 15px;
    }

    &--left {
      left: 15px;
    }
}

.slider__list {
  margin: 0;
  padding: 0;
  display: flex;

  li {
    width: 100%;
    max-width: 567px;
    height: 340px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.apartment__realtor {
  margin-bottom: 10px;
  display: flex;
  background-color: #f7f7f7;
}

.apartment__left, .apartment__right {
  padding: 15px 30px;
}

.apartment__info {
  height: 100%;

  ul {
    margin: 0;
    padding: 0;

    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    li {
      color: #3d3d3d;
      font-family: Roboto;

    }

    li span:first-child {
      color: #3d3d3d;
      font-size: 14px;
      font-weight: 300;
    }

    li span:last-child {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.apartment__info-item--blue {
  color: #455ead;;
}
.apartment__info-item--green {
  color: #5ea512;
}
.apartment__info-item--red {
  color: red;
}
.created {
  color: #8a8a8a;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 300;
}

.realtor {
  display: flex;
  flex-direction: column;
}

.realtor__about {
  display: flex;
}

.realtor__name {
  display: flex;
  flex-direction: column;
  justify-content: center;

  a {
    color: #2672b2;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    text-decoration: underline;
  }

  span {
    color: #404040;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
  }
}

.realtor__ava {
  width: 52px;
  height: 50px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.aparatment__about {
  display: flex;
  min-height: 180px;
}

.apartment__list {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  li {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    color: #3d3d3d;
    font-family: Roboto;
    font-size: 14px;
  }

  li span:first-child {
    font-weight: 500;
  }

  li span:last-child {
    font-weight: 300;
  }
}