$input-height: 40px;

.cabinet-module {
  padding: 0 33px;
  width: 100%;

  .button {
    width: 173px;
  }
}

.personal-block {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 320px;
  margin-bottom: 20px;
  padding: 0px 40px;
  background-color: #ffffff;
  box-shadow: 0 0 9px 0 rgba(62, 62, 62, 0.1);

  &__image {
    padding: 20px 0;
    //margin-right: 60px;
  }
}

.personal__ava {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.personal__wrap-img {
  margin-bottom: 10px;
  position: relative;
}

.personal__img {
  z-index: 1;
  width: 125px;
  height: 125px;
  overflow: hidden;
  border-radius: 50%;

  img {
    object-fit: cover;
    max-width: 100%;
    height: 100%;
  }
}

.personal__delete {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;

  width: 29px;
  height: 29px;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 2px;
  padding-bottom: 5px;

  background-color: #ffffff;
  transition: all .25s ease;
  font-size: 14px;

  &:hover {
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.35);
  }

  span {
    fill: #696969;
    transition: all .25s ease;
  }

  &:hover span {
    fill: #ff2626;
  }
}

.personal__change-ava {
  display: inline-block;
  margin-bottom: 10px;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 300;
  text-align: justify;
  color: #455ead;
}

.personal__edit-ava {
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 300;
  text-align: justify;
  color: #696969;
}

.icon {
  display: inline-block;
  vertical-align: middle;
  height: 1em;
  width: 1em;
  fill: inherit;
  stroke: inherit;
}

.form__group {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;

  &.form__submit {
    margin-top: 25px;
    margin-bottom: 0;
  }
}

.personal__from {
  width: 100%;
  max-width: 1250px;
  display: flex;
  outline: none;
}

.form__col {
  margin-right: 20px;
  padding: 20px 0;
  width: 368px;

  .form__group {
    width: 100%;
  }

  &.form__col-right {
    width: 100%;
    margin-right: 0;
    padding-left: 40px;
  }
}

.form__block {
  display: flex;
  padding-right: 50px;
  border-right: 1px solid #ccc;
}

.form__label {
  margin-bottom: 5px;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 300;
  color: #3b3b3b;
}

.form__input {
  padding: 0 10px;
  height: $input-height;
  line-height: $input-height;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #cccccc;
  outline: none;
}

.form__textarea {
  resize: none;
  min-height: 170px;
}

.form__select {
  height: $input-height;
  line-height: $input-height;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #cccccc;
  outline: none;
}

.form__selected {
  padding: 15px;
  min-height: 188px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: inset 0px 1px 7px 0 rgba(0, 0, 0, 0.08);
}

.selected__remove{
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 100%;
  cursor: pointer;
  color: #000;
}

.selected__remove:before,
.selected__remove:after {
  top: 50%;
  left: 3px;
}

.selected__remove:before {
  content: '';
  position: absolute;
  width: 15px;
  height: 1px;
  background-color: #fc3030;
  transform: rotate(45deg);
}

.selected__remove:after {
  content: '';
  position: absolute;
  width: 15px;
  height: 1px;
  background-color: #fc3030;
  transform: rotate(-45deg);
}

.selected__txt {
  padding-left: 22px;
  font-family: 'Roboto';
  font-size: 14px;
  text-align: left;
  color: #252f4f;
}

.selected__list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.selected__item {
  position: relative;
  width: 139px;
  height: 23px;
  margin-right: 10px;
  margin-bottom: 10px;
  line-height: 23px;
  border-radius: 3px;
  background-color: #ebebeb;
  list-style-type: none;
}

.form__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form__link {
  max-width: 160px;
  height: 26px;
  line-height: 16px;

  a {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 300;
    color: #455ead;
  }
}

.button {
  display: inline-block;
  appearance: none;
  border: 0;
  border-radius: 4px;
  transition: all .25s ease;
  text-decoration: none;
  text-align: center;
}

.button-40 {
  height: $input-height;
  line-height: $input-height;
}

.button-filled.button-red {
  background-color: #f8304c;
  color: #fff;

  &:hover {
    background-color: #f43e69;
  }
}

.button-filled.button-green {
  background-color: #7bbe69;
  color: #fff;

  &:hover {
    background-color: #82d06d;
  }
}

.balance__button {
  .balance__icon {
    padding-right: 14px;
    fill: #fff;
    font-size: 28px;
    line-height: 27px;
  }
}

.personal__spendings {
  width: 100%;
  min-height: 680px;
  background-color: #ffffff;
  box-shadow: 0 0 9px 0 rgba(62, 62, 62, 0.1);
}

.spendings__head {
  display: flex;
  justify-content: space-between;
  align-items: center;

  border: 1px solid #ededed;
}

.spendings__tabs {
  .tabs {
    display: flex;
  }

  .tabs__item {
    width: 231px;
    height: 60px;
    line-height: 60px;
    padding: 0 50px;

    font-family: 'Roboto';
    font-size: 16px;
    color: #232323;
    background-color: #ededed;
    cursor: pointer;

    &.active {
      color: #266eda;
      background-color: #fff;
    }
  }
}

.spaendings__balance {

  .balance {
    display: flex;
    align-items: center;
  }

  .balance__title {
    padding-right: 50px;

    span {
      font-family: 'Roboto';
      font-size: 16px;
      color: #232323;
    }

    span:last-child {
      padding-left: 10px;
      color: #7bbe69;
    }
  }
}

.spendings__list {
  margin: 0;
  padding: 0;
}

.spendings__item {
  height: 70px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  list-style-type: none;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 300;
}

.spendings__date {
  padding-right: 60px;
  color: #818181;
}

.spendings__txt {
  color: #1c1c1c;
  padding-right: 100px;

  &.spendings__txt-placement {
    padding-right: 0;

    span:last-child {
      font-weight: 500;
    }
  }
}

.spendings__link {
  padding-right: 100px;
  color: #266eda;
}

.spendings__status-pending {
  color: #a3a3a3;
}

.spendings__status-ok {
  color: #7bbe69;
}

.spendings__write-offs,
.spending__replenishment {
  display: none;

  &.active {
    display: block;
  }
}

.refill-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  outline: 0;
  background-color: rgba(#000, .5);
  font-family: "Roboto";

  .modal__dialog {
    max-width: 430px;
    display: flex;
    align-items: center;
  }

  .modal__content {
    padding: 50px 30px;
    width: 100%;
  }

  .modal__header {
    justify-content: center;
  }

}

.modal__title {
  margin: 0;
  font-size: 22px;
  font-weight: 400;
}

.modal__header {
  margin-bottom: 45px;
}

.form__light-txt {
  font-size: 14px;
  color: #929292;
}

.avatar-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.7);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s ease .3s, opacity .3s ease;

  &.open {
    visibility: visible;
    opacity: 1;
    transition: opacity .3s ease;
  }

  .modal__dialog {
    margin-top: 60px;
    width: 100%;
    max-width: 667px;
    height: 881px;
  }

  .modal__content {
    min-width: 100%;
    min-height: 100%;

    padding: 50px;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    box-shadow: 0 9px 25px rgba(62,62,62,0.06);

    &.close {
      position: absolute;
      top: 10px;
      right: 15px;
      font-size: 20px;
      text-decoration: none;
    }

    button.close {
      border: 0;
      background: none;
      outline: none;
    }
  }

  .modal__body {
    position: relative;
    flex: 1 1 auto;
  }

}