.docs__block {
  margin: 30px 0;
  padding: 30px;
  width: 100%;
  min-height: 272px;
  border-radius: 4px;
  border: 1px dashed #c4c4c4;
  background-color: #fff;
}

.docs__title {
  margin-bottom: 30px;
  color: #3d3d3d;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
}

.docs__list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  min-height:50px;
}

.docs__item {
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 495px;
  height: 70px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  background-color: #fff;
}

.docs__name {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  color: #2e2e2e;
}

.docs__download {
  position: relative;
  padding: 0 20px;
  display: inline-block;
  width: 104px;
  height: 30px;
  border-radius: 3px;
  border: 1px solid #455ead;
  color: #323232;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 300;
  line-height: 30px;
  text-decoration: none;
  text-align: center;
  text-indent: 10px;
  transition: all .25s ease;
  &:hover {
    background-color: #b0bbdf;
  }

  &:before {
    content: '';
    position: absolute;
    top: calc(50% - 7px);
    left: 8px;
    width: 13px;
    height: 15px;
    background-image: url("../images/download.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.docs__del {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fcfcfc;
  font-size: 25px;
  font-weight: 100;
  cursor: pointer;
  transition: all .25s ease;

  span {
    line-height: 15px;
    display: inline-block;
    color: #171714;
    vertical-align: middle;
    padding-bottom: 7px;
    transition: all .25s ease;
  }

  &:hover {
    background-color: #fff;

    span {
      color: #FF3046;
    }
  }
}

.docs__objects_main {
  padding: 15px 25px;
  width: 100%;
  min-height: 77px;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  background-color: #fafafa;
}

.docs__objects_list {
  margin: 0;
  padding: 0;
  display: flex;

  li {
    margin-right: 20px;
    list-style-type: none;
    color: #3d3d3d;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;

    span:first-child {
      font-weight: 500;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.docs__objects_title {
  margin-bottom: 10px;
  color: #3d3d3d;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
}

.objects-button {
  display: inline-block;
  width: 242px;
  height: 42px;
  line-height: 42px;
  border-radius: 4px;
  border: 1px solid #f8304c;
  text-align: center;
  white-space: nowrap;

  background-color: #fafafa;
  color: #f8304c;
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  transition: all .2s ease;

  &:hover {
    background-color: #f8304c;
    color: #fafafa;
  }
}

.docs__objects_main {
  position: relative;
  padding-right: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:after {
    content: "";
    position: absolute;
    top: calc( 50% - 10px);
    right: 30px;
    width: 19px;
    height: 19px;
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    transform: rotate(135deg);
  }
}

.docs__objects-item.open{

  .docs__objects_main {
    &:after {
      transform: rotate(-45deg);
    }
  }
}

.docs__objects-item {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  .docs__list {
    margin: 20px 0;
  }

  .docs__item {
    margin-right: 18px;
    box-shadow: none;

    &:last-child {
      margin-right: 0;
    }
  }
}