.managers-page {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	button.button {
		display: block;
		border: none;
		outline: none;
	}
	.button {
		border-radius: 4px;
		min-width: 160px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex: none;
		text-decoration: none;
		text-align: center;
		outline: none;
		background: none;
		cursor: pointer;
		user-select: none;
		padding: 0 20px;
		transition: .2s ease;
		i {
			display: inline-block;
			flex: none;
			margin-right: 15px;
		}
		&.red {
			background: #FF3046;
			color: #fff;
			font-size: 14px;
			&:hover {
				background: darken(#FF3046, 8%);
			}
			&:active {
				background: darken(#FF3046, 15%);
			}
		}
		&.red-bordered {
			border: 1px solid #FF3046;
			color: #FF3046;
			font-size: 14px;
			&:hover {
				background: #FF3046;
				color: #fff;
			}
			&:active {
				background: darken(#FF3046, 8%);
				color: #fff;
			}
		}
		&.yellow {
			background: #F4CF00;
			color: #1f1f1f;
			font-size: 14px;
			&:hover {
				background: darken(#F4CF00, 3%);
			}
			&:active {
				background: darken(#F4CF00, 6%);
			}
		}
		&.grey {
			background: #ECECEC;
			color: #1f1f1f;
			font-size: 14px;
			&:hover {
				background: darken(#ECECEC, 3%);
			}
			&:active {
				background: darken(#ECECEC, 6%);
			}
		}
	}
	.page-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #EDEDED;
		padding-right: 30px;
		flex: none;
		.left-controls {
			display: flex;
			align-items: center;
			.search-block {
				position: relative;
				background: #FCFCFC;
				border-right: 1px solid #EDEDED;
				&::before {
					content: '';
					display: block;
					@include sprite($ic1);
					position: absolute;
					top: 50%;
					left: 30px;
					margin-top: -8px;
				}
				input {
					background: transparent;
					position: relative;
					z-index: 2;
					width: 390px;
					height: 60px;
					border: none;
					font-size: 14px;
					color: #242424;
					outline: none;
					padding: 0 30px 0 60px;
					@include input-placeholder {
						color: #BEBEBE
					}
				}
			}
		}
	}
	.page-content {
		overflow-y: auto;
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		background: #FCFCFC;
		.modal-task {
		    visibility: hidden;
		    opacity: 0;
		    width: 295px;
		    position: absolute;
		    top: 29%;
		    left: 45%;
		    border-radius: 4px;
		    // overflow: hidden;
		    border: 1px solid #EBEBEB;
		    background-color: #fff;
		    box-shadow: 0 0 20px -4px rgba(0, 0, 0, 0.15);
		    z-index: 2;
		    transition: visibility ease 0.3s, opacity 0.3s ease;
			&::after {
			    content: "";
			    position: absolute;
			    width: 0;
			    height: 0;
			    left: 40px;
			    top: 2px;
			    border: 7px solid black;
			    border-color: transparent transparent #F5F5F5 #F5F5F5;
			    transform-origin: 0 0;
			    transform: rotate(133deg);
			    box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.05);
			}
		    .not-tasks-modal {
		        font-size: 12px;
		        color: #313131;
		        padding: 20px 15px;
		    }

		    &.open {
		        visibility: visible;
		        opacity: 1;
		        transition-delay: 0s;
		    }

		    .modal-task-title {
		        background-color: #F5F5F5;
		        color: #3b92e3;
		        font-size: 12px;
		        padding: 10px 20px;

		        &.red {
		            color: red;
		        }
		    }

		    .btn-add-modal {
		        background-color: #F5F5F5;
		        color: #3b92e3;
		        font-size: 12px;
		        padding: 10px 20px;
		        text-align: center;
		        cursor: pointer;
		        transition: all 150ms ease;

		        &:hover {
		            background-color: #E8E8E8;
		        }
		    }

		    .modal-task-body {}
		}

		.task-inner-line {
		    border-bottom: 1px solid #E6E6E6;
		    display: flex;
		    justify-content: space-between;
		    flex-wrap: wrap;
		    -webkit-flex-wrap: wrap;
		    -ms-flex-wrap: wrap;
		}

		.modal-task-item {
		    border-bottom: 1px solid #EBEBEB;
		    display: flex;
		    padding: 10px 15px 10px 5px;

		    &:hover {
		        background-color: #F9F9F9;
		    }

		    &:last-child {
		        border-bottom: none;
		    }

		    .modal-task-item-left {
		        display: flex;
		        justify-content: center;
		        flex-wrap: wrap;
		        // align-items: center;
		        padding-top: 10px;
		        width: 55px;
		        min-width: 55px;

		        .dealing-image {
		            width: 20px;
		            height: 20px;
		            border-radius: 100%;
		            overflow: hidden;

		            img {
		                width: 100%;
		            }
		        }
		    }

		    .modal-task-item-right {
		        margin-left: 5px;
		        overflow: hidden;

		        p {
		            font-size: 13px;
		            color: #323232;
		            margin: 5px 0;
		            white-space: nowrap;
		            overflow: hidden;
		            text-overflow: ellipsis;
		        }
		    }
		}

		.icon-call {
		    background: url(../images/ic14.png) center center no-repeat;
		    width: 100%;
		    height: 24px;
		    margin-bottom: 8px;
		}

		.task-line {
		    display: flex;
		    justify-content: space-between;
		    position: relative;
		    width: 100%;
		    padding-right: 8px;
		    border-bottom: 1px solid #ebebeb;

		    &:hover {
		        background-color: #F9F9F9;
		    }

		    &:last-child {
		        border-bottom: 0;
		    }

		    .modal-task-item {
		        border-bottom: 0;
		    }

		    .modal-task-item-right {
		        p {
		            font-size: 15px;
		        }
		    }

		    &-controls {
		        display: flex;
		        position: relative;
		        flex-wrap: wrap;
		        flex-direction: column;
		        .task-icon {
		            position: static !important;
		            margin: 0 !important;
		        }

		        .note-icon {
		            background: url(/images/ui/note.png) center center no-repeat;
		            width: 17px;
		            height: 23px;
		        }

		        .top-controls {
		            display: flex;
		            align-items: center;
		            justify-content: flex-end;
		            span {
		                font-size: 14px;
		                color: #ec4444;
		                margin: 0;
		            }
		            .arrow-control {
		                cursor: pointer;
		                background: url(../images/ui/arrow2.png) center center no-repeat;
		                width: 27px;
		                height: 23px;
		                margin-left: 10px;
		                transition: all 0.3s ease;
		                &:hover {
		                    background: url(../images/ui/arrow2-g.png) center center no-repeat;
		                }
		            }
		        }

		        .icns {
		            display: flex;
		            justify-content: flex-end;
		            margin: 10px 0;
		        }

		        .arrow-icon {
		           background: url(/images/ui/arrow2.png) right center no-repeat;
		            width: 100%;
		            height: 23px;
		        }

		        .time {
		            font-size: 14px;
		            text-align: right;
		            width: 100%;
		            margin: 0;
		        }

		        span {
		            font-size: 14px;
		            color: #b6b6b6;
		            margin: 4px 8px;
		            height: auto;
		        }
		    }
		}

		table {
			width: 100%;
			thead {
				border-bottom: 1px solid #EDEDED;
				background: #fff;
				th {
					color: #2c2c2c;
					font-size: 14px;
					padding: 16px 16px;
					text-align: left;
					font-weight: 500;
					&:nth-child(6),
					&:nth-child(7),
					&:nth-child(8),
					&:nth-child(9),
					&:nth-child(10) {
						text-align: center;
					}
					&:first-child,
					&:last-child {
						padding: 16px 30px;
					}
				}
			}
			tbody {
				tr {
					border-bottom: 1px solid #F5F5F5;
					background: #fff;
					&:hover {
						background: #fdfdfd;
					}
					td {
						padding: 15px 16px;
						font-size: 14px;
						color: #262626;
						&:last-child {
							text-align: right;
							overflow: hidden;
							.button {
								float: right;
							}
						}
						&:first-child,
						&:last-child {
							padding: 16px 30px;
						}
						.realtor {
							display: flex;
							align-items: center;
							justify-content: center;
							.update {
								@include sprite($ic6);
								flex: none;
								margin-right: 5px;
								cursor: pointer;
							}
							.remove {
								@include sprite($ic24);
								flex: none;
								margin-right: 5px;
								cursor: pointer;
								zoom: 1.5;
								-moz-transform: scale(1.5);
								-moz-transform-origin: 0 0;
							}
						}
						i {
							display: block;
							flex: none;
							&.object-icon {
								cursor: pointer;
								margin: 0 auto;
								@include sprite($ic7);
								&.active {
									@include sprite($ic8);
								}
							}
							&.task-icon {
								cursor: pointer;
								margin: 0 auto;
								@include sprite($ic9);
								&.active {
									@include sprite($ic10);
								}
							}
							&.deal-icon {
								cursor: pointer;
								margin: 0 auto;
								@include sprite($ic11);
								&.active {
									@include sprite($ic12);
								}
							}
						}
						.button {
							height: 30px;
							border-radius: 2px;
							i.archive {
								@include sprite($ic13);
							}
						}
					}
				}
			}
		}
		.create-module {
			background-color: rgb(255, 255, 255);
			box-shadow: 0px 0px 9px 0px rgba(62, 62, 62, 0.1);
			width: 444px;
			margin: 30px auto;
			.title {
				text-align: center;
				font-size: 22px;
				color: #3b3b3b;
				padding: 35px 38px;
			}
			.body {
				.avatar-block {
					padding: 0 38px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					.image {
						width: 125px;
						height: 125px;
						border-radius: 100%;
						overflow: hidden;
						flex: none;
						img {
							display: block;
							width: 100%;
						}
					}
					.controls {
						width: 173px;
						flex: none;
						.button {
							margin-bottom: 10px;
						}
						.remove {
							margin: 0 auto;
							cursor: pointer;
							color: #272727;
							font-size: 14px;
							text-decoration: underline;
							padding: 5px 0;
							text-align: center;
							&:hover {
								text-decoration: none;
							}
						}
					}
				}
				.form-elements {
					padding: 20px 38px;
					&.bottom-elements {
						border-top: 1px solid #EBEBEB;
					}
					.form-group {
						margin: 15px 0;
						label {
							font-size: 14px;
							display: block;
							color: #3b3b3b;
							margin-bottom: 5px;
						}
						input {
							height: 40px;
							border-radius: 4px;
							border: 1px solid #C8C8C8;
							padding: 0 15px;
							outline: none;
							color: #000;
							font-size: 12px;
							font-weight: 300;
							width: 100%;
							&:focus {
								border-color: darken(#C8C8C8, 16%)
							}
						}
						select {
							height: 40px;
							border-radius: 4px;
							border: 1px solid #C8C8C8;
							padding: 0 11px;
							outline: none;
							color: #000;
							font-size: 12px;
							font-weight: 300;
							width: 100%;
							&:focus {
								border-color: darken(#C8C8C8, 16%)
							}
						}
					}
					.change-password {
						font-size: 14px;
						color: #3b3b3b;
						font-weight: 300;
						text-decoration: underline;
						cursor: pointer;
						&:hover {
							text-decoration: none;
						}
					}
					.button-wrapper {
						display: flex;
						justify-content: flex-end;
						margin-top: 15px;
					}
				}
			}
		}
	}
}
