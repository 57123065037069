.apartments-view-page {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	button.button {
		display: block;
		border: none;
	}
	.button {
		border-radius: 4px;
		min-width: 157px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		text-decoration: none;
		text-align: center;
		outline: none;
		background: none;
		cursor: pointer;
		user-select: none;
		padding: 0 20px;
		transition: .2s ease;
		i {
			display: inline-block;
			flex: none;
			margin-right: 15px;
		}
		&.red {
			background: #FF3046;
			color: #fff;
			font-size: 14px;
			&:hover {
				background: darken(#FF3046, 8%);
			}
			&:active {
				background: darken(#FF3046, 15%);
			}
		}
		&.red-bordered {
			border: 1px solid #FF3046;
			color: #FF3046;
			font-size: 14px;
			&:hover {
				background: #FF3046;
				color: #fff;
			}
			&:active {
				background: darken(#FF3046, 8%);
				color: #fff;
			}
		}
		&.yellow {
			background: #F4CF00;
			color: #1f1f1f;
			font-size: 14px;
			&:hover {
				background: darken(#F4CF00, 3%);
			}
			&:active {
				background: darken(#F4CF00, 6%);
			}
		}
		&.grey {
			background: #ECECEC;
			color: #1f1f1f;
			font-size: 14px;
			&:hover {
				background: darken(#ECECEC, 3%);
			}
			&:active {
				background: darken(#ECECEC, 6%);
			}
		}
		&.blue {
			background: #185EDC;
			color: #fff;
			font-size: 14px;
			&:hover {
				background: darken(#185EDC, 3%);
			}
			&:active {
				background: darken(#185EDC, 6%);
			}
		}
		&.green {
			background: #54BA56;
			color: #fff;
			font-size: 14px;
			&:hover {
				background: darken(#54BA56, 3%);
			}
			&:active {
				background: darken(#54BA56, 6%);
			}
		}
		&.green-border {
			background: transparent;
			color: #1f1f1f;
			font-size: 14px;
			border: 1px solid #54BA56;
			&:hover {
				background: darken(#54BA56, 3%);
				color: #fff;
				border-color: darken(#54BA56, 3%);
			}
			&:active {
				background: darken(#54BA56, 6%);
				color: #fff;
				border-color: darken(#54BA56, 6%);
			}
		}
		&.blue-border {
			background: transparent;
			color: #185EDC;
			font-size: 14px;
			border: 1px solid #185EDC;
			&:hover {
				background: darken(#185EDC, 3%);
				color: #fff;
				border-color: darken(#185EDC, 3%);
			}
			&:active {
				background: darken(#185EDC, 6%);
				color: #fff;
				border-color: darken(#185EDC, 6%);
			}
		}
	}
	.page-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #EDEDED;
		flex: none;
		.buttons {
			display: flex;
			align-items: center;
			padding: 10px 30px;
		}
		.controls {
			display: flex;
			align-items: center;
			.search-block {
				position: relative;
				background: #FCFCFC;
				border-right: 1px solid #EDEDED;
				&::before {
					content: '';
					display: block;
					@include sprite($ic1);
					position: absolute;
					top: 50%;
					left: 30px;
					margin-top: -8px;
				}
				input {
					background: transparent;
					position: relative;
					z-index: 2;
					width: 390px;
					height: 60px;
					border: none;
					font-size: 14px;
					color: #242424;
					outline: none;
					padding: 0 30px 0 60px;
					@include input-placeholder {
						color: #BEBEBE
					}
				}
			}
			.select-wrapper {
				display: flex;
				align-items: center;
				margin-left: 30px;
				span {
					color: #242424;
					font-size: 14px;
					margin-right: 15px;
				}
				select {
					font-size: 14px;
					color: #242424;
					border-radius: 4px;
					border: 1px solid #EDEDED;
					height: 40px;
					width: 200px;
					padding: 0 10px;
					outline: none;
				}
			}
		}
	}
	.page-common {
		flex: 1 1 auto;
		overflow-y: auto;
		.common-head {
			display: flex;
			justify-content: space-between;
			padding: 12px 30px;
			border-bottom: 1px solid #E0E0E0;
			a {
				color: #2672b2;
				&:hover {
					text-decoration: none;
				}
			}
			.left-column {
				.navigation {
					margin: 0 -30px;
					display: flex;
				    position: relative;
    				bottom: -13px;
					.nav-selector {
						position: relative;
						border-bottom: 2px solid transparent;
						font-size: 14px;
						text-decoration: underline;
						color: #455ead;
						font-weight: 300;
						cursor: pointer;
						height: 40px;
						margin: 0 30px;
						display: flex;
						align-items: center;
						&::before {
						    content: "";
						    position: absolute;
						    width: 0;
						    height: 0;
						    box-sizing: border-box;
						    border: 5px solid black;
						    border-color: transparent transparent #3E50AA #3E50AA;
						    transform-origin: 0 0;
						    transform: rotate(-45deg);
						    top: 100%;
						    right: 50%;
						    display: none;
						    margin-left: calc(14.14/-2px);
						}
						&.active {
							border-color: #3E50AA;
							color: #5e5e5e;
							text-decoration: none;
							&::before {
								display: block;
							}
						}
						&:hover {
							color: #5e5e5e;
							text-decoration: none;
						}
					}
				}
			}
			.info-blocks {
				display: flex;
				.block {
					margin-right: 25px;
					&.general {
						.name {
							color: #3b3b3b;
						}
						.desc {
							background: #3E50AB;
							color: #fff;
						}
					}
					.name {
						font-weight: 300;
						font-size: 14px;
						color: #afafaf;
						margin-bottom: 6px;
					}
					.desc {
						display: flex;
						align-items: center;
						height: 28px;
						border-radius: 3px;
						background: #F6F6F6;
						padding: 0 20px;
						font-size: 14px;
						color: #404040;
						font-weight: 300;
					}
				}
				.info-block {
					margin-left: 40px;
					.name {
						color: #3b3b3b;
						font-weight: 300;
						font-size: 14px;
						margin-bottom: 6px;
					}
					.border-block {
						display: flex;
						align-items: center;
						width: 226px;
						height: 60px;
						border-radius: 4px;
						border: 1px dashed #BFBFBF;
						padding: 3px 10px 3px 3px;
						.image {
							width: 51px;
							height: 51px;
							flex: none;
							img {
								display: block;
								width: 100%;
							}
						}
						.text {
							padding: 0 10px;
							flex: 1 1 auto;
							.title {
								font-size: 14px;
								font-weight: 300;
								margin-bottom: 2px;
								display: block;
							}
							.phone {
								font-size: 12px;
								color: #404040;
								font-weight: 300;
							}
						}
						.refresh {
							@include sprite($ic6);
							flex: none;
							cursor: pointer;
						}
						.remove {
							@include sprite($ic24);
							flex: none;
							margin-right: 5px;
							cursor: pointer;
							zoom: 1.5;
							-moz-transform: scale(1.5);
							-moz-transform-origin: 0 0;
						}
					}
				}
			}
		}
		.general-container {
			.module-container {
				display: none;
			}
		}
		.general-module {
			flex-wrap: wrap;
			padding: 24px 12px;
			&.open {
				display: flex;
			}
			.column {
				flex: 1 1 1px;
				display: flex;
				flex-direction: column;
			}
			.box {
				background: #F6F6F6;
				border-radius: 4px;
				// padding: 25px;
				margin: 12px;
				overflow: hidden;
				.title {
					font-size: 14px;
					color: #404040;
					font-weight: 500;
					background: #E8E8E8;
					padding: 10px 25px;
				}
				.box-body {
					padding: 25px;
					.form-groups {
						display: flex;
						margin: -7px;
						margin-bottom: 7px;
						.form-group {
							margin: 7px;
							flex: 1 1 1px;
						}
					}
					.form-group {
						display: flex;
						align-items: center;
						margin-bottom: 14px;
						&.wrap {
							display: block;
							label {
								margin-right: 0;
								margin-bottom: 5px;
							}
						}
						label {
							font-size: 14px;
							color: #afafaf;
							display: block;
							margin-right: 10px;
							min-width: 60px;
							flex: none;
							font-weight: 300;
						}
						input {
							padding: 0 10px;
							font-size: 14px;
							color: #404040;
							width: 100%;
							height: 30px;
							border-radius: 3px;
							border: 1px solid #D1D1D1;
							&.field-changed{
								border-color: rgba(31, 229, 35, 0.8);
								box-shadow: 0 1px 1px rgba(75, 229, 133, 0.07) inset, 0 0 8px rgba(77, 229, 121, 0.6);
								outline: 0 none;
							}
						}
						select {
							padding: 0 5px;
							font-size: 14px;
							color: #404040;
							width: 100%;
							height: 30px;
							border-radius: 3px;
							border: 1px solid #D1D1D1;
							&.field-changed{
								border-color: rgba(31, 229, 35, 0.8);
								box-shadow: 0 1px 1px rgba(75, 229, 133, 0.07) inset, 0 0 8px rgba(77, 229, 121, 0.6);
								outline: 0 none;
							}
						}
						textarea {
							padding: 10px;
							font-size: 14px;
							color: #404040;
							width: 100%;
							height: 115px;
							border-radius: 3px;
							border: 1px solid #D1D1D1;
							resize: none;
							&.field-changed{
								border-color: rgba(31, 229, 35, 0.8);
								box-shadow: 0 1px 1px rgba(75, 229, 133, 0.07) inset, 0 0 8px rgba(77, 229, 121, 0.6);
								outline: 0 none;
							}
						}
					}
					.adress-box {
						&.dop-options {
							.form-group {
								label {
									width: 160px;
									flex: none;
									text-align: right;
								}
							}
						}
						.checkboxes {
							display: flex;
							flex-wrap: wrap;
							margin: -7px;
							.checkbox-block {
								height: 48px;
								margin: 7px;
								overflow: hidden;
								input[type=checkbox] {
									position: absolute;
									opacity: 0;
									visibility: hidden;
									&.field-changed + label i{
										border-color: rgba(31, 229, 35, 0.8);
										box-shadow: 0 1px 1px rgba(75, 229, 133, 0.07) inset, 0 0 8px rgba(77, 229, 121, 0.6);
										outline: 0 none;
									}
								}
								input[type=checkbox]:checked ~ label {
									background: #F6F6F6;
								}
								input[type=checkbox]:focus {
									& ~ label {
										i {
											&:before {
												opacity: 0.5;
											}
										}
									}
								}
								input[type=checkbox]:checked ~ label i:before {
									opacity: 1;
								}
								label {
									border-radius: 4px;
									display: flex;
									align-items: center;
									font-size: 14px;
									color: #404040;
									cursor: pointer;
									position: relative;
									flex: none;
									user-select: none;
									&:hover {
										i {
											&:before {
												opacity: 0.5;
											}
										}
									}
									i {
										flex: none;
										display: block;
										width: 22px;
										height: 22px;
										display: flex;
										justify-content: center;
										align-items: center;
										margin-right: 7px;
										border: 1px solid #D1D1D1;
										border-radius: 2px;
										background: #fff;
										overflow: hidden;
										&:before {
											content: '';
											display: block;
											@include sprite($ic30);
											opacity: 0;
										}
									}
								}
								&.need-block {

								}
							}
						}
						.streets-module {
							display: flex;
							flex-wrap: wrap;
							margin: -6px;
							padding-left: 70px;
							.street-item {
								margin: 6px;
								height: 28px;
								border-radius: 3px;
								color: #404040;
								font-size: 14px;
								display: flex;
								align-items: center;
								background: #BED7FA;
								padding-right: 15px;
								&:hover {
									background: darken(#BED7FA, 3%);
								}
								.remove-street {
									cursor: pointer;
									height: 28px;
									width: 28px;
									flex: none;
									display: flex;
									justify-content: center;
									align-items: center;
									border-radius: 3px 0 0 3px;
									margin-right: 5px;
									&:hover {
										background: #FF3046;
									}
									&::before {
										content: '';
										display: block;
										@include sprite($ic29)
									}
								}
							}
						}
					}
					.input-label{
						width: 135px;
						flex: none;
						text-align: right;
					}
				}
			}
		}
		.photos-module {
			flex-wrap: wrap;
			padding: 15px;
			&.open {
				display: flex;
			}
			.photo-item {
				border-radius: 4px;
				background-color: rgb(255, 255, 255);
				box-shadow: 0px 0px 3px 0px rgba(15, 16, 16, 0.07);
				width: 185px;
				flex: none;
				margin: 15px;
				.controls {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 5px 10px;
					.orientation-buttons {
						display: flex;
						.orientation {
							width: 25px;
							height: 25px;
							cursor: pointer;
							display: flex;
							justify-content: center;
							align-items: center;
							margin-right: 5px;
							&:hover {
								opacity: .5;
							}
							svg {
								width: 16px;
								height: 17px;
							}
							&.right {
								svg {
									transform: scale(-1, 1)
								}
							}
						}
					}
					.remove {
						width: 25px;
						height: 25px;
						display: flex;
						justify-content: center;
						align-items: center;
						cursor: pointer;
						&:hover {
							opacity: .5;
						}
						svg {
							width: 15px;
							height: 15px;
						}
					}
				}
				.image {
					height: 150px;
					overflow: hidden;
					width: 100%;
					position: relative;
					background: #FBFBFB;
					display: block;
					outline: none;
					&:hover {
						.overlay {
							opacity: 1;
						}
					}
					.overlay {
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						background: rgba(#191919, .8);
						display: flex;
						justify-content: center;
						align-items: center;
						opacity: 0;
						transition: .4s ease;
						svg {
							width: 26px;
							height: 26px;
						}
					}
					img {
						display: block;
						max-height: 100%;
					}
				}
				.public {
					display: flex;
					align-items: center;
					justify-content: space-between;
					cursor: pointer;
					font-size: 14px;
					color: #3c3c3c;
					padding: 0 10px 0 20px;
					height: 39px;
					&:hover {
						i {
							&::before {
								opacity: .5;
							}
						}
					}
					&.active {
						i {
							&::before {
								opacity: 1;
							}
						}
					}
					i {
					    flex: none;
					    display: block;
					    width: 22px;
					    height: 22px;
					    display: flex;
					    justify-content: center;
					    align-items: center;
					    border: 1px solid #D1D1D1;
					    border-radius: 2px;
					    background: #fff;
					    overflow: hidden;
					    &::before {
					    	content: '';
						    display: block;
						   	@include sprite($ic30);
						    opacity: 0;
					    }
					}
				}
			}
		}
	}
}

.apartments-index-page {
	flex: 1 1 auto;
	overflow-y: auto;
	overflow-x: hidden;
	position: relative;
	.page-wrapper {
		min-height: calc(100vh - 60px);
		display: flex;
		flex: 1 1 auto;
	}
	.page-column {
		flex: none;
		width: 281px;
		border-right: 1px solid #E0E0E0;
		position: relative;
		.client-module {
			padding: 20px;
			border-bottom: 1px solid #E0E0E0;
			.info-block {
				.name {
					color: #3b3b3b;
					font-weight: 300;
					font-size: 14px;
					margin-bottom: 6px;
				}
				.border-block {
					display: flex;
					align-items: center;
					width: 100%;
					height: 60px;
					border-radius: 4px;
					border: 1px dashed #BFBFBF;
					padding: 3px 10px 3px 3px;
					.image {
						width: 51px;
						height: 51px;
						flex: none;
						img {
							display: block;
							width: 100%;
						}
					}
					.text {
						padding: 0 10px;
						flex: 1 1 auto;
						.title {
							font-size: 14px;
							margin-bottom: 2px;
							display: block;
							color: #2672b2;
							&:hover {
								text-decoration: none;
							}
						}
						.phone {
							font-size: 12px;
							color: #404040;
							font-weight: 300;
						}
					}
					.refresh {
						@include sprite($ic6);
						flex: none;
						cursor: pointer;
					}
					.remove {
						@include sprite($ic24);
						flex: none;
						margin-right: 5px;
						cursor: pointer;
						zoom: 1.5;
						-moz-transform: scale(1.5);
						-moz-transform-origin: 0 0;
					}
				}
			}
			.button {
				width: 100%;
				height: 28px;
				border-radius: 3px;
				background: #FF3046;
				color: #fff;
				font-size: 14px;
				margin-top: 20px;
				cursor: pointer;
				display: flex;
				justify-content: center;
				align-items: center;
				user-select: none;
				&.disabled {
					pointer-events: none;
					color: #3a3a3a;
					background: #CFCFCF;
				}
				&:hover {
					background: darken(#FF3046, 8%);
				}
				&:active {
					background: darken(#FF3046, 12%);
				}
			}
		}
		.sort-appartments-module {
			.appartment-item {
				border-bottom: 1px solid #E0E0E0;
				margin: 20px;
				position: relative;
				border: 1px solid transparent;
				border-radius: 4px;
				padding: 10px;
				background: #FAFAFA;
				transition: .2s ease;
				&:hover {
					border-color: #FF2C4B;
				}
				.remove {
					display: flex;
					justify-content: center;
					align-items: center;
					position: absolute;
					right: 0;
					top: 0;
					width: 30px;
					height: 30px;
					cursor: pointer;
					opacity: .5;
					&:hover {
						opacity: 1;
					}
					&::before {
						content: '';
						display: block;
						@include sprite($ic29)
					}
				}
				p {
					font-size: 12px;
					color: #3d3d3d;
					font-weight: 400;
					margin: 0;
					margin-bottom: 8px;
					&.ttl {
						font-size: 14px;
					}
					span {
						margin-right: 10px;
					}
					strong {
						font-weight: 500;
					}
				}
				.controls {
					padding-top: 10px;
					margin: 0 -6px;
					display: flex;
					justify-content: flex-end;
					flex: none;
					.button {
						flex: none;
						min-width: 90px;
						padding: 0 10px;
						height: 28px;
						border-radius: 3px;
						display: flex;
						justify-content: center;
						align-items: center;
						position: relative;
						font-size: 14px;
						color: #fff;
						background: #FF3046;
						font-weight: 300;
						cursor: pointer;
						margin: 0 6px;
						user-select: none;
						text-decoration: none;
						&:hover {
							background: darken(#FF3046, 3%);
						}
						&:active {
							background: darken(#FF3046, 10%);
						}
					}
				}
			}
		}
		.apartments-module {
			.empty {
				font-size: 14px;
				color: #b7b7b7;
				text-align: center;
				font-weight: 300;
				padding: 20px;
				padding-top: 30px;
			}
			.appartment-item {
				border-bottom: 1px solid #E0E0E0;
				padding: 20px;
				position: relative;
				.remove {
					display: flex;
					justify-content: center;
					align-items: center;
					position: absolute;
					right: 0;
					top: 0;
					width: 30px;
					height: 30px;
					cursor: pointer;
					opacity: .5;
					&:hover {
						opacity: 1;
					}
					&::before {
						content: '';
						display: block;
						@include sprite($ic29)
					}
				}
				&:hover {
					background: #fbfbfb;
				}
				p {
					font-size: 12px;
					color: #3d3d3d;
					font-weight: 400;
					margin: 0;
					margin-bottom: 8px;
					&.ttl {
						font-size: 14px;
					}
					span {
						margin-right: 10px;
					}
					strong {
						font-weight: 500;
					}
				}
				.controls {
					padding-top: 10px;
					margin: 0 -6px;
					display: flex;
					justify-content: space-between;
					flex: none;
					.button {
						flex: none;
						min-width: 110px;
						padding: 0 10px;
						height: 28px;
						border-radius: 3px;
						display: flex;
						justify-content: center;
						align-items: center;
						position: relative;
						font-size: 12px;
						color: #343434;
						font-weight: 300;
						cursor: pointer;
						margin: 0 6px;
						user-select: none;
						text-decoration: none;
						i {
							display: block;
							flex: none;
							position: absolute;
							left: 10px;
							top: 50%;
						}
						&.affix {
							border: 1px solid #2CA000;
							padding-left: 40px;
							&:hover {
								background: #2CA000;
								color: #fff;
								i {
									@include sprite($ic31-w);
								}
							}
							&:active {
								background: darken(#2CA000, 6%);
								border-color: darken(#2CA000, 6%);
							}
							i {
								@include sprite($ic31);
								margin-top: -($ic31-height/2);
							}
						}
						&.fast-view {
							border: 1px solid #3E50AA;
							padding-left: 30px;
							background: #fff;
							&:hover {
								background: #3E50AA;
								color: #fff;
								i {
									@include sprite($ic32-w);
								}
							}
							&:active {
								background: darken(#3E50AA, 6%);
								border-color: darken(#3E50AA, 6%);
							}
							i {
								@include sprite($ic32);
								margin-top: -($ic32-height/2);
							}
						}
						&.view {
							background: #3E50AA;
							color: #fff;
							&:hover {
								background: darken(#3E50AA, 6%);
							}
							&:active {
								background: darken(#3E50AA, 12%);
							}
						}
					}
				}
			}
		}
	}
	.page-common {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		overflow: auto;
		.page-common-head {
			width: 100%;
			flex: none;
			.head-filters-nav {
				display: flex;
				margin: 0 -6px;
				padding: 0 12px;
				padding-top: 8px;
				.filter-nav {
					font-size: 14px;
					color: #455ead;
					cursor: pointer;
					border-radius: 3px 3px 0 0;
					font-weight: 500;
					height: 28px;
					padding: 0 10px;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					margin: 0 6px;
					text-decoration: underline;
					&:hover {
						// background: #EFEFEF;
						text-decoration: none;
					}
					&.active {
						background: #EAEAEA;
						color: #444444;
						text-decoration: none;
					}
				}
			}
			.head-filters-common {
				.head-filters-module {
					display: none;
				}
				.filters-module {
					.filters-module-head {
						display: flex;
						justify-content: space-between;
						align-items: center;
						background: #EAEAEA;
						.form-elements {
							display: flex;
							align-items: flex-start;
							padding: 10px 24px;
							.form-column {
								.form-row {
									display: flex;
									margin: 0 -5px;
									&:last-child {
										.form-group {
											margin-bottom: 0;
										}
									}
									.form-group {
										display: flex;
										align-items: center;
										margin: 0 5px;
										margin-bottom: 20px;
										label {
											display: block;
											font-size: 14px;
											color: #252525;
											font-weight: 300;
											margin-right: 7px;
											// width: 95px;
										}
										input {
											width: 122px;
											height: 28px;
											border-radius: 3px;
											border: 1px solid #D1D1D1;
											background: #fff;
											padding: 0 5px;
											font-size: 14px;
											color: #404040;
										}
									}
								}
							}
							.areas {
								display: flex;
								flex-wrap: wrap;
								align-items: flex-start;
								margin: -3px;
								padding-left: 20px;
								.area {
									font-size: 12px;
									color: #404040;
									display: flex;
									align-items: center;
									height: 16px;
									border-radius: 4px;
									background: #BFDDFA;
									padding: 0 8px 0 0;
									margin: 3px;
									.remove-area {
										cursor: pointer;
										opacity: .4;
										padding: 0 8px;
										height: 16px;
										flex: none;
										display: flex;
										justify-content: center;
										align-items: center;
										&::before {
											content: '';
											display: block;
											@include sprite($ic29);
										}
										&:hover {
											opacity: 1;
										}
									}
								}
							}
						}
						.controls {
							display: flex;
							justify-content: flex-end;
							flex: none;
							padding-right: 30px;
							.button {
								flex: none;
								min-width: 157px;
								padding: 0 20px;
								height: 28px;
								border-radius: 3px;
								display: flex;
								justify-content: center;
								align-items: center;
								position: relative;
								font-size: 14px;
								color: #343434;
								font-weight: 300;
								cursor: pointer;
								margin: 0 6px;
								user-select: none;
								text-decoration: none;
								i {
									display: block;
									flex: none;
									position: absolute;
									left: 20px;
									top: 50%;
								}
								&.show-on-map {
									background: #3E50AA;
									color: #fff;
									padding-left: 60px;
									&:hover {
										background: darken(#3E50AA, 5%);
									}
									&:active {
										background: darken(#3E50AA, 10%);
									}
									i {
										@include sprite($ic33);
										margin-top: -($ic33-height/2);
									}
								}
							}
						}
					}
					.filters-module-body {
						display: flex;
						justify-content: space-between;
						align-items: center;
					    border-bottom: 1px solid #E0E0E0;
						padding: 10px 0;
						&.other-filters-module {
							display: none;
							margin-top: -1px;
							position: relative;
							background: #fff;
							&.open {
								display: flex;
							}
							.form-elements {
								flex-wrap: wrap;
								.elements-row {
									width: 100%;
									padding: 5px 0;
									display: flex;
								}
								.elements-column {
									padding: 5px 0;
								}
							}
						}
						.form-elements {
							display: flex;
							margin: 0 -12px;
							padding: 10px 24px;
							.areas {
								display: flex;
								flex-wrap: wrap;
								align-items: flex-start;
								margin: -3px;
								padding-left: 20px;
								.area {
									font-size: 12px;
									color: #404040;
									display: flex;
									align-items: center;
									height: 16px;
									border-radius: 4px;
									background: #BFDDFA;
									padding: 0 8px 0 0;
									margin: 3px;
									.remove-area {
										cursor: pointer;
										opacity: .4;
										padding: 0 8px;
										height: 16px;
										flex: none;
										display: flex;
										justify-content: center;
										align-items: center;
										&::before {
											content: '';
											display: block;
											@include sprite($ic29);
										}
										&:hover {
											opacity: 1;
										}
									}
								}
							}
							.elements-column {
								margin: 0 12px;
								.form-group {
									display: flex;
									align-items: center;
									margin-bottom: 8px;
									&.wrap {
										display: block;
										label {
											width: 100%;
											margin-right: 0;
											margin-bottom: 7px;
										}
									}
									&:last-child {
										margin-bottom: 0;
									}
									label {
										display: block;
										font-size: 14px;
										color: #252525;
										font-weight: 300;
										margin-right: 7px;
									}
									span {
										display: block;
										font-size: 14px;
										color: #252525;
										font-weight: 300;
										margin-left: 7px;
									}
									input,
									select {
										width: 122px;
										height: 28px;
										border-radius: 3px;
										border: 1px solid #D1D1D1;
										background: #fff;
										padding: 0 15px;
										font-size: 14px;
										color: #404040;
										&.small {
											width: 50px;
										}
									}
									select {
										padding: 0 10px;
									}
									.inputs-wrapper {
										display: flex;
										align-items: center;
										.delimiter {
											font-size: 14px;
											color: #404040;
											padding: 0 8px;
											flex: none;
										}
									}
									.checkbox-block {
										display: flex;
										align-items: center;
										height: 28px;
										overflow: hidden;
										input[type=checkbox] {
											position: absolute;
											opacity: 0;
											visibility: hidden;
										}
										input[type=checkbox]:checked ~ label {
											// background: #F6F6F6;
										}
										input[type=checkbox]:focus {
											& ~ label {
												i {
													&:before {
														opacity: 0.5;
													}
												}
											}
										}
										input[type=checkbox]:checked ~ label i:before {
											opacity: 1;
										}
										label {
											border-radius: 4px;
											display: flex;
											align-items: center;
											font-size: 14px;
											color: #252525;
											cursor: pointer;
											position: relative;
											flex: none;
											user-select: none;
											&:hover {
												i {
													&:before {
														opacity: 0.5;
													}
												}
											}
											i {
												flex: none;
												display: block;
												width: 22px;
												height: 22px;
												display: flex;
												justify-content: center;
												align-items: center;
												margin-right: 7px;
												border: 1px solid #D1D1D1;
												border-radius: 2px;
												background: #fff;
												overflow: hidden;
												&:before {
													content: '';
													display: block;
													@include sprite($ic30);
													opacity: 0;
												}
											}
										}
									}
									.radios {
										display: flex;
										align-items: center;
										height: 28px;
										margin: 0 -2px;
										.radio-box {
											position: relative;
											margin: 0 2px;
											input[type=radio],input[type=checkbox] {
												position: absolute;
												opacity: 0;
												visibility: hidden;
											}
											input[type=radio]:checked ~ label,input[type=checkbox]:checked ~ label {
												background: #3E50AA;
												border-color: #3E50AA;
												color: #fff;
											}
											input[type=radio]:focus,input[type=checkbox]:focus {
												& ~ label {
													i {
														&:before {
															opacity: 0.5;
														}
													}
												}
											}
											input[type=radio]:checked ~ label i:before,input[type=checkbox]:checked ~ label i:before {
												opacity: 1;
											}
											label {
												width: 28px;
												height: 28px;
												border-radius: 3px;
												border: 1px solid #D1D1D1;
												background: #fff;
												font-size: 14px;
												color: #404040;
												display: flex;
												justify-content: center;
												align-items: center;
												cursor: pointer;
											}
										}
									}
								}
							}
						}
						.form-controls {
							width: 275px;
							flex-wrap: wrap;
							padding-top: 8px;
							padding-right: 30px;
							display: flex;
							.button {
								flex: none;
								width: 172px;
								padding: 0 20px;
								height: 28px;
								border-radius: 3px;
								display: flex;
								justify-content: center;
								align-items: center;
								position: relative;
								font-size: 14px;
								color: #343434;
								font-weight: 300;
								cursor: pointer;
								margin: 0 6px;
								user-select: none;
								text-decoration: none;
								margin-bottom: 8px;
								&:last-child {
									margin-bottom: 0;
								}
								i {
									display: block;
									flex: none;
									position: absolute;
									right: 10px;
									top: 50%;
								}
								&.other-filters {
									background: #3E50AA;
									color: #fff;
									padding-right: 30px;
									padding-left: 10px;
									&:hover {
										background: darken(#3E50AA, 5%);
									}
									&:active {
										background: darken(#3E50AA, 10%);
									}
									i {
										@include sprite($ic34);
										margin-top: -($ic34-height/2);
									}
								}
								&.find {
									background: #FF3046;
									color: #fff;
									&:hover {
										background: darken(#FF3046, 5%);
									}
									&:active {
										background: darken(#FF3046, 10%);
									}
								}
								&.reset {
									background: gray;
									color: #fff;
									&:hover {
										background: darken(gray, 5%);
									}
									&:active {
										background: darken(gray, 10%);
									}
								}
							}
						}
					}
				}
			}
		}
		.page-common-content {
			flex: 1 1 auto;
			.apartments-list {
				.apartment-item {
					width: 100%;
					border-bottom: 1px solid #E0E0E0;
					padding: 20px;
					display: flex;
					align-items: center;
					&:hover {
						background: #FCFCFC;
					}
					&.clicked {
						background: #EBEBEB;
					}
					.column {
						display: flex;
						align-items: center;
						flex: 1 1 auto;
					}
					.info-line {
						display: flex;
						align-items: center;
						flex-wrap: wrap;
						&:nth-child(2) {
							padding: 0 50px;
						}
						&:nth-child(3) {
							padding-left: 0;
						}
						.info-block {
							// min-width: 100%;
							flex: 1 1 100%;
							margin: 2px 0;
							span {
								color: #3d3d3d;
								font-size: 14px;
								font-weight: 300;
								white-space: nowrap;
								&:not(:first-child) {
									margin-left: 20px;
								}
								strong {
									font-weight: 600;
								}
							}
						}
						.controls {
							margin: 0 -6px;
							display: flex;
							flex: none;
							.button {
								flex: none;
								min-width: 157px;
								padding: 0 20px;
								height: 28px;
								border-radius: 3px;
								display: flex;
								justify-content: center;
								align-items: center;
								position: relative;
								font-size: 14px;
								color: #343434;
								font-weight: 300;
								cursor: pointer;
								margin: 0 6px;
								user-select: none;
								text-decoration: none;
								i {
									display: block;
									flex: none;
									position: absolute;
									left: 20px;
									top: 50%;
								}
								&.affix {
									border: 1px solid #2CA000;
									padding-left: 40px;
									&:hover {
										background: #2CA000;
										color: #fff;
										i {
											@include sprite($ic31-w);
										}
									}
									&:active {
										background: darken(#2CA000, 6%);
										border-color: darken(#2CA000, 6%);
									}
									i {
										@include sprite($ic31);
										margin-top: -($ic31-height/2);
									}
								}
								&.fast-view {
									border: 1px solid #3E50AA;
									padding-left: 40px;
									&:hover {
										background: #3E50AA;
										color: #fff;
										i {
											@include sprite($ic32-w);
										}
									}
									&:active {
										background: darken(#3E50AA, 6%);
										border-color: darken(#3E50AA, 6%);
									}
									i {
										@include sprite($ic32);
										margin-top: -($ic32-height/2);
									}
								}
								&.view {
									background: #3E50AA;
									color: #fff;
									&:hover {
										background: darken(#3E50AA, 6%);
									}
									&:active {
										background: darken(#3E50AA, 12%);
									}
								}
							}
						}
					}
				}
			}
		}
	}
	#search-apartments-list{
		&.blur{
			transition: 1s -webkit-filter linear;
			-webkit-filter: blur(5px);
			-moz-filter: blur(5px);
			-o-filter: blur(5px);
			-ms-filter: blur(5px);
			filter: blur(5px);
		}
	}
}

.drag-photo {
	font-size: 20px;
	height: 24px;
	transition: all .3s ease;
	cursor: -webkit-grab;
	svg {
		width: 1em;
		height: 1em;
		fill: #222220;
	}

	&:hover {
		opacity: 0.5;
	}

	&:active {
		cursor: -webkit-grabbing;
	}
}

@media screen and (max-width: 1600px) {
	.apartments-index-page .page-common .page-common-head,
	.apartments-index-page .page-common .page-common-content{
    width: 1685px;
  }
}