.contacts-page {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
  overflow-x: auto;
  .add-phone-bg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    background: rgba(#000, 0.8);
    display: none;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    z-index: 100;
    &.open {
      display: flex;
    }
    .add-phone-modal {
      max-width: 420px;
      width: 100%;
      margin: 0 auto;
      background: #fff;
      border-radius: 10px;
      overflow: hidden;
      padding: 20px 40px;
      margin-top: -60px;
      .title {
        text-align: center;
        font-size: 20px;
        font-weight: 300;
        margin-bottom: 20px;
      }
      input {
        background: transparent;
        width: 100%;
        height: 40px;
        border-radius: 3px;
        border: none;
        font-size: 14px;
        color: #242424;
        border: 1px solid #ebebeb;
        outline: none;
        padding: 0 15px;
        margin-bottom: 10px;
        @include input-placeholder {
          color: #bebebe;
        }
      }
      button {
        width: 100%;
      }
    }
  }
  button.button {
    display: block;
    border: none;
  }
  .button {
    border-radius: 4px;
    min-width: 157px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    text-align: center;
    outline: none;
    background: none;
    cursor: pointer;
    user-select: none;
    padding: 0 20px;
    transition: 0.2s ease;
    &.cell-button {
      width: 105px;
      min-width: 92px;
    }
    i {
      display: inline-block;
      flex: none;
      margin-right: 15px;
    }
    &.red {
      background: #ff3046;
      color: #fff;
      font-size: 14px;
      &:hover {
        background: darken(#ff3046, 8%);
      }
      &:active {
        background: darken(#ff3046, 15%);
      }
    }
    &.red-bordered {
      border: 1px solid #ff3046;
      color: #ff3046;
      font-size: 14px;
      &:hover {
        background: #ff3046;
        color: #fff;
      }
      &:active {
        background: darken(#ff3046, 8%);
        color: #fff;
      }
    }
    &.yellow {
      background: #f4cf00;
      color: #1f1f1f;
      font-size: 14px;
      &:hover {
        background: darken(#f4cf00, 3%);
      }
      &:active {
        background: darken(#f4cf00, 6%);
      }
    }
    &.grey {
      background: #ececec;
      color: #1f1f1f;
      font-size: 14px;
      &:hover {
        background: darken(#ececec, 3%);
      }
      &:active {
        background: darken(#ececec, 6%);
      }
    }
    &.blue {
      background: #185edc;
      color: #fff;
      font-size: 14px;
      &:hover {
        background: darken(#185edc, 3%);
      }
      &:active {
        background: darken(#185edc, 6%);
      }
    }
    &.green {
      background: #54ba56;
      color: #fff;
      font-size: 14px;
      &:hover {
        background: darken(#54ba56, 3%);
      }
      &:active {
        background: darken(#54ba56, 6%);
      }
    }
    &.green-border {
      background: transparent;
      color: #1f1f1f;
      font-size: 14px;
      border: 1px solid #54ba56;
      &:hover {
        background: darken(#54ba56, 3%);
        color: #fff;
        border-color: darken(#54ba56, 3%);
      }
      &:active {
        background: darken(#54ba56, 6%);
        color: #fff;
        border-color: darken(#54ba56, 6%);
      }
    }
    &.blue-border {
      background: transparent;
      color: #185edc;
      font-size: 14px;
      border: 1px solid #185edc;
      &:hover {
        background: darken(#185edc, 3%);
        color: #fff;
        border-color: darken(#185edc, 3%);
      }
      &:active {
        background: darken(#185edc, 6%);
        color: #fff;
        border-color: darken(#185edc, 6%);
      }
    }
  }
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ededed;
    flex: none;
    .buttons {
      display: flex;
      align-items: center;
      padding: 10px 30px;
    }
    .controls {
      display: flex;
      align-items: center;
      .search-block {
        position: relative;
        background: #fcfcfc;
        border-right: 1px solid #ededed;
        &::before {
          content: "";
          display: block;
          @include sprite($ic1);
          position: absolute;
          top: 50%;
          left: 30px;
          margin-top: -8px;
        }
        input {
          background: transparent;
          position: relative;
          z-index: 2;
          width: 390px;
          height: 60px;
          border: none;
          font-size: 14px;
          color: #242424;
          outline: none;
          padding: 0 30px 0 60px;
          @include input-placeholder {
            color: #bebebe;
          }
        }
      }
      .select-wrapper {
        display: flex;
        align-items: center;
        margin-left: 30px;
        span {
          color: #242424;
          font-size: 14px;
          margin-right: 15px;
        }
        select {
          font-size: 14px;
          color: #242424;
          border-radius: 4px;
          border: 1px solid #ededed;
          height: 40px;
          width: 200px;
          padding: 0 10px;
          outline: none;
        }
      }
    }
  }
  .page-content {
    overflow-y: auto;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    background: #fcfcfc;
    position: relative;
    .overlay-create-contact {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow-y: auto;
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(#000, 0.7);
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s ease 0.3s, opacity 0.3s ease;
      &.open {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.3s ease;
      }
      .modal-create-contact {
        width: 460px;
        background: #fff;
        .title {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          border-bottom: 2px solid #cccccc;
          font-size: 18px;
          color: #3b3b3b;
          font-weight: 500;
          padding: 25px 15px;
        }
        .body {
          padding: 45px;
          form {
            color: #3b3b3b;
            .form-group {
              margin-bottom: 15px;
              label {
                display: block;
                font-size: 14px;
                font-weight: 300;
                margin-bottom: 5px;
              }
              .input-wrapper {
                position: relative;
                .status {
                  width: 105px;
                  height: 22px;
                  border-radius: 2px;
                  border: 1px solid #ecd000;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: center;
                  font-size: 14px;
                  color: #474747;
                  font-weight: 300;
                  position: absolute;
                  right: 9px;
                  top: 50%;
                  margin-top: -11px;
                }
                input,
                select,
                textarea {
                  width: 100%;
                  height: 40px;
                  border-radius: 3px;
                  border: 1px solid #c8c8c8;
                  padding: 0 15px;
                  font-size: 14px;
                  outline: none;
                  background: transparent;
                  position: relative;
                  z-index: 2;
                  &:focus {
                    border-color: lighten(#185edc, 20%);
                  }
                  &.full {
                    border-color: #185edc;
                  }
                  &.input-with-status {
                    padding-right: 122px;
                  }
                }
              }
            }
            .button-wrapper {
              display: flex;
              justify-content: flex-end;
            }
          }
        }
      }
    }
    .modal-task {
      visibility: hidden;
      opacity: 0;
      width: 295px;
      position: absolute;
      top: 29%;
      left: 45%;
      border-radius: 4px;
      // overflow: hidden;
      border: 1px solid #ebebeb;
      background-color: #fff;
      box-shadow: 0 0 20px -4px rgba(0, 0, 0, 0.15);
      z-index: 2;
      transition: visibility ease 0.3s, opacity 0.3s ease;
      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        left: 40px;
        top: 2px;
        border: 7px solid black;
        border-color: transparent transparent #f5f5f5 #f5f5f5;
        transform-origin: 0 0;
        transform: rotate(133deg);
        box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.05);
      }
      .not-tasks-modal {
        font-size: 12px;
        color: #313131;
        padding: 20px 15px;
      }

      &.open {
        visibility: visible;
        opacity: 1;
        transition-delay: 0s;
      }

      .modal-task-title {
        background-color: #f5f5f5;
        color: #3b92e3;
        font-size: 12px;
        padding: 10px 20px;

        &.red {
          color: red;
        }
      }

      .btn-add-modal {
        background-color: #f5f5f5;
        color: #3b92e3;
        font-size: 12px;
        padding: 10px 20px;
        text-align: center;
        cursor: pointer;
        transition: all 150ms ease;

        &:hover {
          background-color: #e8e8e8;
        }
      }
    }
    .task-inner-line {
      border-bottom: 1px solid #e6e6e6;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .modal-task-item {
      border-bottom: 1px solid #ebebeb;
      display: flex;
      padding: 10px 15px 10px 5px;
      &:hover {
        background-color: #f9f9f9;
      }

      &:last-child {
        border-bottom: none;
      }
      .modal-task-item-left {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        // align-items: center;
        padding-top: 10px;
        width: 55px;
        min-width: 55px;
        .dealing-image {
          width: 20px;
          height: 20px;
          border-radius: 100%;
          overflow: hidden;

          img {
            width: 100%;
          }
        }
      }
      .modal-task-item-right {
        margin-left: 5px;
        overflow: hidden;

        p {
          font-size: 13px;
          color: #323232;
          margin: 5px 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .overdue-color {
      background-color: #ffd;
    }
    .icon-call {
      background: url(../images/ic14.png) center center no-repeat;
      width: 100%;
      height: 24px;
      margin-bottom: 8px;
    }
    .task-line {
      display: flex;
      justify-content: space-between;
      position: relative;
      width: 100%;
      padding-right: 8px;
      border-bottom: 1px solid #ebebeb;
      &:hover {
        background-color: #f9f9f9;
      }
      &:last-child {
        border-bottom: 0;
      }
      .modal-task-item {
        border-bottom: 0;
      }
      .modal-task-item-right {
        p {
          font-size: 15px;
        }
      }
      &-controls {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        flex-direction: column;
        .task-icon {
          position: static !important;
          margin: 0 !important;
        }
        .note-icon {
          background: url(/images/ui/note.png) center center no-repeat;
          width: 17px;
          height: 23px;
        }
        .top-controls {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          span {
            font-size: 14px;
            color: #ec4444;
            margin: 0;
          }
          .arrow-control {
            cursor: pointer;
            background: url(../images/ui/arrow2.png) center center no-repeat;
            width: 27px;
            height: 23px;
            margin-left: 10px;
            transition: all 0.3s ease;
            &:hover {
              background: url(../images/ui/arrow2-g.png) center center no-repeat;
            }
          }
        }
        .icns {
          display: flex;
          justify-content: flex-end;
          margin: 10px 0;
        }
        .arrow-icon {
          background: url(/images/ui/arrow2.png) right center no-repeat;
          width: 100%;
          height: 23px;
        }
        .time {
          font-size: 14px;
          text-align: right;
          width: 100%;
          margin: 0;
        }
        span {
          font-size: 14px;
          color: #b6b6b6;
          margin: 4px 8px;
          height: auto;
        }
      }
    }
    table {
      width: 100%;
      thead {
        border-bottom: 1px solid #ededed;
        background: #fff;
        th {
          color: #2c2c2c;
          font-size: 14px;
          padding: 16px 16px;
          text-align: left;
          font-weight: 500;
          &:nth-child(6),
          &:nth-child(7),
          &:nth-child(8),
          &:nth-child(9),
          &:nth-child(10) {
            text-align: center;
          }
          &:first-child,
          &:last-child {
            padding: 16px 30px;
          }
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid #f5f5f5;
          background: #fff;
          &:hover {
            background: #fdfdfd;
          }
          &.clicked {
            background: #ececec;
          }
          td {
            padding: 15px 16px;
            font-size: 14px;
            color: #262626;
            &:first-child,
            &:last-child {
              padding: 16px 30px;
              &.row-buttons {
                display: flex;
              }
            }
            .realtor {
              display: flex;
              align-items: center;
              justify-content: center;
              .icon-group {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 50px;
              }
              .update {
                @include sprite($ic6);
                flex: none;
                margin-right: 5px;
                cursor: pointer;
              }
              .remove {
                @include sprite($ic24);
                flex: none;
                margin-right: 5px;
                cursor: pointer;
                zoom: 1.5;
                -moz-transform: scale(1.5);
                -moz-transform-origin: 0 0;
              }
            }
            i {
              display: block;
              flex: none;
              &.object-icon {
                cursor: pointer;
                margin: 0 auto;
                @include sprite($ic7);
                &.active {
                  @include sprite($ic8);
                }
              }
              &.object-icon.is-selling {
                background-image: url("../images/house-green.svg");
                background-repeat: no-repeat;
                background-position: center;
                width: 29px;
                height: 24px;
              }
              &.task-icon {
                cursor: pointer;
                margin: 0 auto;
                @include sprite($ic9);
                &.active {
                  @include sprite($ic10);
                }
              }
              &.deal-icon {
                cursor: pointer;
                margin: 0 auto;
                @include sprite($ic11);
                &.active {
                  @include sprite($ic12);
                }
              }
              font-style: normal;
              font-size: 22px;
              text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff,
                0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff,
                -1px 1px 0 #fff;
              text-align: -webkit-center;
              color: #38bb22;
            }
            .button {
              height: 30px;
              border-radius: 2px;
              i.archive {
                @include sprite($ic13);
              }
            }
          }
        }
      }
    }
    .contacts-page-view {
      flex: 1 1 auto;
      .contacts-view-panel {
        display: flex;
        justify-content: space-between;
        padding: 25px 33px;
        background: #fff;
        box-shadow: 0px 4px 12px 0px rgba(62, 62, 62, 0.11);
        position: relative;
        z-index: 2;
        .client-common {
          display: flex;
          align-items: center;
          .ava {
            flex: none;
            width: 128px;
            height: 128px;
            overflow: hidden;
            img {
              display: block;
              width: 100%;
            }
          }
          .info {
            display: flex;
            padding-left: 45px;
            .elements-group {
              padding-right: 80px;
              display: flex;
              flex-direction: column;
              .phone-group {
                position: relative;
                .open-phone-dropdown {
                  cursor: pointer;
                  position: absolute;
                  height: 28px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  left: 100%;
                  margin-left: 15px;
                  top: 0;
                  &:hover {
                    opacity: 0.7;
                  }
                  &::before {
                    content: "";
                    display: block;
                    @include sprite($ic35);
                    flex: none;
                  }
                }
                .phone-dropdown {
                  position: absolute;
                  right: 0;
                  top: 100%;
                  margin-top: 15px;
                  border-radius: 3px;
                  background-color: rgb(247, 247, 247);
                  box-shadow: 0px 0px 4px 0px rgba(15, 16, 16, 0.26);
                  width: 428px;
                  overflow: hidden;
                  z-index: 3;
                  display: none;
                  &.open {
                    display: block;
                  }
                  .phones {
                    .phone-item {
                      font-size: 14px;
                      color: #404040;
                      padding: 9px 10px;
                      cursor: pointer;
                      &:nth-child(odd) {
                        background: #ebebeb;
                      }
                      &:hover {
                        background: lighten(#ebebeb, 1%);
                      }
                      span.type {
                        margin-left: 15px;
                      }
                    }
                  }
                  .button {
                    height: 35px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    font-size: 14px;
                    // font-weight: 300;
                    color: #3d63dd;
                    border-radius: 0px;
                    transition: 0s ease;
                    &:hover {
                      background: #3d63dd;
                      color: #fff;
                      i {
                        @include sprite($ic20);
                      }
                    }
                    i {
                      @include sprite($ic19);
                      flex: none;
                      margin-right: 10px;
                    }
                  }
                }
              }
              .form-groups-wrapper {
                display: flex;
                justify-content: space-between;
                align-self: flex-end;
                width: 190px;
                .form-group {
                  display: block;
                  flex: 1 1 auto;
                  &.gender-group {
                    width: 75px;
                    flex: none;
                    margin-right: 5px;
                    select {
                      width: 100%;
                      text-align: center;
                      height: 28px;
                      border: 1px solid #d1d1d1;
                      outline: none;
                      padding: 0 7px;
                      font-size: 16px;
                      color: #404040;
                      border-radius: 3px;
                      cursor: pointer;
                    }
                  }
                  input {
                    width: 100%;
                    text-align: center;
                    padding: 0;
                  }
                  label {
                    margin-right: 0;
                    text-align: left;
                    margin-bottom: 5px;
                  }
                }
              }
              .form-group {
                display: flex;
                align-items: center;
                margin-bottom: 5px;
                label {
                  font-size: 14px;
                  color: #afafaf;
                  margin-right: 12px;
                  flex: 1 1 auto;
                  text-align: right;
                  display: flex;
                  align-items: center;
                  height: 28px;
                }
                input {
                  flex: none;
                  width: 190px;
                  height: 28px;
                  border: 1px solid #d1d1d1;
                  outline: none;
                  padding: 0 12px;
                  font-size: 16px;
                  color: #404040;
                  border-radius: 3px;
                  display: block;
                  &:focus {
                    border-color: lighten(#185edc, 20%);
                  }
                  &.full {
                    border-color: #185edc;
                  }
                }
              }
            }
          }
        }
        .client-controls {
          .form-group {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 37px;
            select {
              width: 190px;
              text-align: center;
              height: 28px;
              border: 1px solid #d1d1d1;
              outline: none;
              padding: 0 7px;
              font-size: 16px;
              color: #404040;
              border-radius: 3px;
              cursor: pointer;
            }
          }
          .buttons-wrapper {
            .buttons {
              display: flex;
              justify-content: flex-end;
              margin-top: 5px;
              .button {
                margin-left: 5px;
                height: 28px;
                min-width: 190px;
                padding: 0 5px;
              }
            }
          }
        }
      }
      .contacts-view-content {
        padding: 0 33px;
        background: #fff;
        .content-selectors {
          display: flex;
          justify-content: space-between;
          border-bottom: 2px solid #f0f0f0;
          .wrapper {
            width: 100%;
            display: flex;
            margin: 0 -10px -2px;
            .content-selector {
              font-size: 16px;
              color: #266eda;
              border-bottom: 2px solid transparent;
              cursor: pointer;
              padding: 12px 0;
              margin: 0 10px;
              &:hover,
              &.active {
                color: #232323;
              }
              &.active {
                border-color: #185edc;
              }
            }
            .content-selector--left {
              margin-left: auto;
            }
          }
        }
        .content-modules {
          .content-module {
            display: none;
            &.open {
              display: block;
            }
            .general-information {
              .top-blocks {
                display: flex;
                flex-wrap: wrap;
                padding: 10px 0;
                margin: 0 -10px;
                .block {
                  flex: 1 1 380px;
                  border-radius: 3px;
                  border: 1px solid #f7f7f7;
                  overflow: hidden;
                  margin: 10px;
                  box-shadow: 0px 1px 3px 0px rgba(62, 62, 62, 0.11);
                  .title {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    color: #1f1f1f;
                    font-size: 14px;
                    background: #f6f6f6;
                    border-bottom: 1px solid #f7f7f7;
                    padding: 15px;
                    font-weight: 500;
                  }
                  .task-body {
                    font-size: 14px;
                    padding: 12px;
                    .common-info {
                      display: flex;
                      align-items: center;
                      padding: 20px 0;
                      .icon {
                        flex: none;
                        padding-left: 8px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        i {
                          display: block;
                          &.ic14 {
                            @include sprite($ic14);
                          }
                        }
                      }
                      .info {
                        flex: 1 1 auto;
                        color: #323232;
                        padding: 0 20px;
                        .name {
                          font-weight: 500;
                        }
                        .client {
                          font-weight: 300;
                        }
                      }
                      .status-icon {
                        @include sprite($ic9);
                        flex: none;
                        cursor: pointer;
                      }
                    }
                    .bottom {
                      display: flex;
                      justify-content: flex-end;
                      .date {
                        color: #97b27b;
                      }
                    }
                  }
                  .body {
                    padding: 20px 15px;
                    .info {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      font-size: 14px;
                      i {
                        display: block;
                        flex: none;
                        margin-right: 25px;
                        &.ic5 {
                          @include sprite($ic5);
                        }
                        &.ic12 {
                          @include sprite($ic12);
                        }
                      }
                      a {
                        color: #202020;
                        margin-left: 25px;
                        &:hover {
                          text-decoration: none;
                        }
                      }
                    }
                    .button-wrapper {
                      padding-top: 20px;
                      display: flex;
                      justify-content: center;
                      .button {
                        width: 197px;
                        height: 28px;
                        i {
                          display: block;
                          flex: none;
                          @include sprite($ic19);
                        }
                        &:hover {
                          i {
                            @include sprite($ic20);
                          }
                        }
                      }
                    }
                  }
                }
                .comment-block {
                  flex: 1 1 380px;
                  margin: 10px;
                  .title {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    color: #1f1f1f;
                    font-size: 14px;
                    padding: 15px;
                    font-weight: 500;
                    i {
                      @include sprite($ic21);
                      flex: none;
                      margin-right: 18px;
                    }
                  }
                  textarea {
                    border: 1px solid #e5e5e5;
                    padding: 10px 15px;
                    height: 117px;
                    width: 100%;
                    resize: none;
                    border-radius: 3px;
                    line-height: 1.5em;
                    outline: none;
                    display: block;
                    font-size: 14px;
                    color: #1f1f1f;
                    &:focus {
                      border-color: lighten(#185edc, 20%);
                    }
                    &.full {
                      border-color: #185edc;
                    }
                    @include input-placeholder {
                      color: #c3c3c3;
                    }
                  }
                }
              }
            }
            .need-module {
              margin-top: 20px;
              box-shadow: 0px 0px 15px 0px rgba(207, 207, 207, 0.15);
              .need-tabs {
                display: flex;
                border-bottom: 1px solid #efefef;
                .need-tab {
                  display: flex;
                  align-items: center;
                  color: #404040;
                  font-size: 14px;
                  padding: 0 20px;
                  height: 48px;
                  cursor: pointer;
                  background: #ebebeb;
                  margin-bottom: -1px;
                  border: 1px solid #efefef;
                  border-bottom: 0;
                  border-top: 0;
                  &:first-child {
                    border-left: 0;
                  }
                  &:hover {
                    background: #f3f3f3;
                  }
                  &.active {
                    background: #fff;
                  }
                  &.status-true {
                    i {
                      background: #54ba56;
                    }
                  }
                  i {
                    margin-left: 15px;
                    width: 12px;
                    height: 12px;
                    display: block;
                    flex: none;
                    border-radius: 100%;
                    background: #ff3046;
                  }
                }
              }
              .need-pages {
                .need-page {
                  display: none;
                  padding: 18px 28px;
                  .need-buy-page {
                    .page-head {
                      display: flex;
                      .checkbox-block {
                        height: 48px;
                        margin: 0 4px;
                        overflow: hidden;
                        &.need-block {
                          background: #f6f6f6;
                        }
                        input[type="checkbox"] {
                          position: absolute;
                          opacity: 0;
                          visibility: hidden;
                        }
                        input[type="checkbox"]:checked ~ label {
                          // background: #F6F6F6;
                        }
                        input[type="checkbox"]:focus {
                          & ~ label {
                            background: #f6f6f6;
                            i {
                              &:before {
                                opacity: 0.5;
                              }
                            }
                          }
                        }
                        input[type="checkbox"]:checked ~ label i:before {
                          opacity: 1;
                        }
                        label {
                          border-radius: 4px;
                          height: 48px;
                          padding: 0 20px;
                          display: flex;
                          align-items: center;
                          font-size: 14px;
                          color: #404040;
                          cursor: pointer;
                          position: relative;
                          flex: none;
                          user-select: none;
                          &:hover {
                            // background: #F6F6F6;
                            i {
                              &:before {
                                opacity: 0.5;
                              }
                            }
                          }
                          i {
                            flex: none;
                            display: block;
                            width: 22px;
                            height: 22px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 7px;
                            border: 1px solid #d1d1d1;
                            border-radius: 2px;
                            background: #fff;
                            &:before {
                              content: "";
                              display: block;
                              @include sprite($ic30);
                              opacity: 0;
                            }
                          }
                        }
                        &.need-block {
                        }
                      }
                      .checkboxes {
                        display: flex;
                        align-items: center;
                        padding-left: 40px;
                        .name {
                          font-size: 14px;
                          color: #3a3a3a;
                          margin-right: 10px;
                          font-weight: 500;
                        }
                      }
                    }
                    .page-wrapper {
                      display: flex;
                      flex-wrap: wrap;
                      padding: 30px 0;
                      .block-wrapper {
                        flex: 1 1 1px;
                        margin: 11px;
                        .block-title {
                          text-align: center;
                          text-transform: uppercase;
                          background: darken(#fff, 3%);
                          // margin-bottom: 3px;
                          border-radius: 4px 4px 0 0;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          padding: 9px 0;
                          font-size: 14px;
                          border: 1px dashed #bfbfbf;
                          border-bottom: 0;
                        }
                      }
                      .border-block {
                        border: 1px dashed #bfbfbf;
                        border-radius: 0px 0px 4px 4px;
                        padding: 20px;
                        min-height: 500px;
                        flex: 1 1 1px;
                        .empty {
                          width: 100%;
                          height: 500px;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          text-align: center;
                          padding: 20px;
                          font-size: 16px;
                          color: #b7b7b7;
                          font-weight: 300;
                        }
                        .item {
                          background: #f9faf9;
                          border: 1px solid #e6e6e6;
                          border-radius: 4px;
                          padding: 18px;
                          margin-bottom: 20px;
                          position: relative;
                          padding-left: 30px;
                          cursor: grab;
                          transition: 0.3s ease;
                          &:hover {
                            box-shadow: 0px 0px 20px 0px rgba(#000, 0.1);
                          }
                          &:before {
                            content: "";
                            display: block;
                            @include sprite($ic36);
                            position: absolute;
                            left: 10px;
                            top: 20px;
                          }
                          &:last-child {
                            margin-bottom: 0;
                          }
                          .info-line {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            &:not(:last-child) {
                              margin-bottom: 5px;
                            }
                            .title {
                              font-size: 14px;
                              font-weight: 500;
                              color: #3d3d3d;
                            }
                            span {
                              font-size: 14px;
                              font-weight: 400;
                              color: #3d3d3d;
                            }
                            a {
                              font-size: 14px;
                              color: #2970da;
                              &:hover {
                                text-decoration: none;
                              }
                            }
                            .buttons {
                              display: flex;
                              .button {
                                min-width: auto;
                                height: 19px;
                                padding: 0 15px;
                                font-size: 14px;
                                font-weight: 300;
                                margin-left: 10px;
                                &.disabled {
                                  pointer-events: none;
                                }
                              }
                            }
                            select {
                              border-style: solid;
                              border-width: 1px;
                              border-color: rgb(153, 153, 153);
                              border-radius: 3px;
                              background-color: rgb(255, 255, 255);
                              width: 201px;
                              height: 31px;
                              font-size: 13px;
                              padding: 0 10px;
                              margin: 5px 0;
                            }
                          }
                        }
                      }
                      .box {
                        background: #f6f6f6;
                        border-radius: 10px;
                        padding: 25px;
                        margin: 12px;
                        flex: 1 1 30%;
                        min-width: 400px;
                        .title {
                          font-size: 14px;
                          color: #404040;
                          padding-bottom: 25px;
                          font-weight: 500;
                        }
                        .box-body {
                          .form-groups {
                            display: flex;
                            margin: -7px;
                            margin-bottom: 7px;
                            .form-group {
                              margin: 7px;
                              flex: 1 1 1px;
                            }
                          }
                          .form-group {
                            display: flex;
                            align-items: center;
                            margin-bottom: 14px;
                            label {
                              font-size: 14px;
                              color: #afafaf;
                              display: block;
                              margin-right: 10px;
                              min-width: 60px;
                              flex: none;
                            }
                            input {
                              padding: 0 10px;
                              font-size: 14px;
                              color: #404040;
                              width: 100%;
                              height: 30px;
                              border-radius: 3px;
                              border: 1px solid #d1d1d1;
                            }
                          }

                          .adress-box {
                            .streets-module {
                              display: flex;
                              flex-wrap: wrap;
                              margin: -6px;
                              padding-left: 70px;
                              .street-item {
                                margin: 6px;
                                height: 28px;
                                border-radius: 3px;
                                color: #404040;
                                font-size: 14px;
                                display: flex;
                                align-items: center;
                                background: #bed7fa;
                                padding-right: 15px;
                                &:hover {
                                  background: darken(#bed7fa, 3%);
                                }
                                .remove-street {
                                  cursor: pointer;
                                  height: 28px;
                                  width: 28px;
                                  flex: none;
                                  display: flex;
                                  justify-content: center;
                                  align-items: center;
                                  border-radius: 3px 0 0 3px;
                                  margin-right: 5px;
                                  &:hover {
                                    background: #ff3046;
                                  }
                                  &::before {
                                    content: "";
                                    display: block;
                                    @include sprite($ic29);
                                  }
                                }
                              }
                            }
                          }
                          .options-box {
                            .form-groups {
                              .form-group {
                                label {
                                  min-width: auto;
                                }
                              }
                            }
                          }
                          .features-box {
                            .checkboxes {
                              display: flex;
                              flex-wrap: wrap;
                              margin: -7px;
                              .checkbox-block {
                                height: 48px;
                                margin: 7px;
                                overflow: hidden;
                                input[type="checkbox"] {
                                  position: absolute;
                                  opacity: 0;
                                  visibility: hidden;
                                }
                                input[type="checkbox"]:checked ~ label {
                                  background: #f6f6f6;
                                }
                                input[type="checkbox"]:focus {
                                  & ~ label {
                                    i {
                                      &:before {
                                        opacity: 0.5;
                                      }
                                    }
                                  }
                                }
                                input[type="checkbox"]:checked
                                  ~ label
                                  i:before {
                                  opacity: 1;
                                }
                                label {
                                  border-radius: 4px;
                                  display: flex;
                                  align-items: center;
                                  font-size: 14px;
                                  color: #404040;
                                  cursor: pointer;
                                  position: relative;
                                  flex: none;
                                  user-select: none;
                                  &:hover {
                                    i {
                                      &:before {
                                        opacity: 0.5;
                                      }
                                    }
                                  }
                                  i {
                                    flex: none;
                                    display: block;
                                    width: 22px;
                                    height: 22px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    margin-right: 7px;
                                    border: 1px solid #d1d1d1;
                                    border-radius: 2px;
                                    background: #fff;
                                    &:before {
                                      content: "";
                                      display: block;
                                      @include sprite($ic30);
                                      opacity: 0;
                                    }
                                  }
                                }
                                &.need-block {
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  .need-buy-module {
                    display: flex;
                    .needs-column {
                      min-width: 330px;
                      max-width: 330px;
                      flex: 1 1 auto;
                      border: 1px dashed #bfbfbf;
                      border-radius: 4px;
                      padding: 12px;
                      .empty {
                        .button {
                          height: 28px;
                          margin: 0;
                        }
                        .text {
                          text-align: center;
                          font-size: 14px;
                          color: #b7b7b7;
                          font-weight: 300;
                          padding: 12px 0 0;
                        }
                      }
                      .need-item {
                        padding: 12px;
                        background: #f9f9f9;
                        border: 1px solid #e5e5e5;
                        border-radius: 4px;
                        margin-bottom: 12px;
                        &:last-child {
                          margin-bottom: 0;
                        }
                        .controls {
                          display: flex;
                          margin: 0 -4px;
                          padding-top: 5px;
                          .button {
                            margin: 0 4px;
                            height: 19px;
                            font-size: 12px;
                            min-width: 1px;
                          }
                        }
                        p {
                          font-size: 14px;
                          color: #3d3d3d;
                          font-weight: 300;
                          margin: 0 0 5px;
                          strong {
                            font-weight: 500;
                          }
                        }
                        &.js-active-needs {
                          border-color: blue;
                        }
                      }
                    }
                    .needs-common {
                      flex: 1 1 auto;
                      padding-left: 25px;
                      form {
                        .head {
                          display: flex;
                          align-items: center;
                          margin-bottom: 20px;
                          .num {
                            background: #e8e8e8;
                            border-radius: 3px;
                            color: #404040;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 0 15px;
                            height: 28px;
                            font-size: 14px;
                          }
                          span {
                            font-size: 14px;
                            color: #404040;
                            padding: 0 25px;
                          }
                          select {
                            padding: 0 5px;
                            font-size: 14px;
                            color: #404040;
                            width: 194px;
                            height: 30px;
                            height: 28px;
                            border-radius: 3px;
                            border: 1px solid #e8e8e8;
                            margin-right: 25px;
                          }
                          .button {
                            height: 28px;
                            min-width: 1px;
                          }
                        }
                        .blocks {
                          display: flex;
                          margin: -12px;
                          .blocks-column {
                            margin: 12px;
                            flex: 1 1 1px;
                          }
                          .box {
                            background: #f6f6f6;
                            border-radius: 10px;
                            margin: 0 0 12px;
                            flex: 1 1 30%;
                            min-width: 400px;
                            overflow: hidden;
                            .title {
                              font-size: 14px;
                              color: #404040;
                              font-weight: 500;
                              background: #e8e8e8;
                              padding: 20px 25px;
                            }
                            .box-body {
                              padding: 25px;
                              .form-groups {
                                display: flex;
                                margin: -7px;
                                margin-bottom: 7px;
                                .form-group {
                                  margin: 7px;
                                  flex: 1 1 1px;
                                }
                              }
                              .checkbox-block {
                                height: 48px;
                                overflow: hidden;
                                input[type="checkbox"] {
                                  position: absolute;
                                  opacity: 0;
                                  visibility: hidden;
                                }
                                input[type="checkbox"]:checked ~ label {
                                  background: #f6f6f6;
                                }
                                input[type="checkbox"]:focus {
                                  & ~ label {
                                    i {
                                      &:before {
                                        opacity: 0.5;
                                      }
                                    }
                                  }
                                }
                                input[type="checkbox"]:checked
                                  ~ label
                                  i:before {
                                  opacity: 1;
                                }
                                label {
                                  border-radius: 4px;
                                  display: flex;
                                  align-items: center;
                                  font-size: 14px;
                                  color: #404040;
                                  cursor: pointer;
                                  position: relative;
                                  flex: none;
                                  user-select: none;
                                  &:hover {
                                    i {
                                      &:before {
                                        opacity: 0.5;
                                      }
                                    }
                                  }
                                  i {
                                    flex: none;
                                    display: block;
                                    width: 22px;
                                    height: 22px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    margin-right: 7px;
                                    border: 1px solid #d1d1d1;
                                    border-radius: 2px;
                                    background: #fff;
                                    &:before {
                                      content: "";
                                      display: block;
                                      @include sprite($ic30);
                                      opacity: 0;
                                    }
                                  }
                                }
                                &.need-block {
                                }
                              }
                              .form-group {
                                display: flex;
                                align-items: center;
                                margin-bottom: 14px;
                                &.wrap {
                                  display: block;
                                  label {
                                    margin-right: 0;
                                    width: 100%;
                                    margin-bottom: 10px;
                                  }
                                }
                                button.button {
                                  outline: none;
                                  display: block;
                                  border: none;
                                }
                                .button {
                                  text-decoration: none;
                                  display: flex;
                                  justify-content: center;
                                  align-items: center;
                                  cursor: pointer;
                                  height: 30px;
                                  border-radius: 3px;
                                  width: 100%;
                                  background: #54ba56;
                                  color: #fff;
                                  font-size: 14px;
                                  cursor: pointer;
                                  &:hover {
                                    background: darken(#54ba56, 6%);
                                  }
                                }
                                textarea {
                                  padding: 10px;
                                  font-size: 14px;
                                  color: #404040;
                                  min-height: 100px;
                                  resize: vertical;
                                  width: 100%;
                                  border-radius: 3px;
                                  border: 1px solid #d1d1d1;
                                  outline: none;
                                  &:focus {
                                    border-color: lighten(#185edc, 20%);
                                  }
                                  &.full {
                                    border-color: #185edc;
                                  }
                                }
                                label {
                                  font-size: 14px;
                                  color: #afafaf;
                                  display: block;
                                  margin-right: 10px;
                                  min-width: 60px;
                                  flex: none;
                                }
                                input {
                                  outline: none;
                                  padding: 0 10px;
                                  font-size: 14px;
                                  color: #404040;
                                  width: 100%;
                                  height: 30px;
                                  border-radius: 3px;
                                  border: 1px solid #d1d1d1;
                                  &:focus {
                                    border-color: lighten(#185edc, 20%);
                                  }
                                  &.full {
                                    border-color: #185edc;
                                  }
                                }
                                select {
                                  outline: none;
                                  padding: 0 5px;
                                  font-size: 14px;
                                  color: #404040;
                                  width: 100%;
                                  height: 30px;
                                  border-radius: 3px;
                                  border: 1px solid #d1d1d1;
                                  &:focus {
                                    border-color: lighten(#185edc, 20%);
                                  }
                                  &.full {
                                    border-color: #185edc;
                                  }
                                }
                              }
                              .adress-box {
                                .streets-module {
                                  display: flex;
                                  flex-wrap: wrap;
                                  margin: -6px;
                                  padding-left: 70px;
                                  .street-item {
                                    margin: 6px;
                                    height: 28px;
                                    border-radius: 3px;
                                    color: #404040;
                                    font-size: 14px;
                                    display: flex;
                                    align-items: center;
                                    background: #bed7fa;
                                    padding-right: 15px;
                                    &:hover {
                                      background: darken(#bed7fa, 3%);
                                    }
                                    .remove-street {
                                      cursor: pointer;
                                      height: 28px;
                                      width: 28px;
                                      flex: none;
                                      display: flex;
                                      justify-content: center;
                                      align-items: center;
                                      border-radius: 3px 0 0 3px;
                                      margin-right: 5px;
                                      &:hover {
                                        background: #ff3046;
                                      }
                                      &::before {
                                        content: "";
                                        display: block;
                                        @include sprite($ic29);
                                      }
                                    }
                                  }
                                }
                              }
                              .options-box {
                                .form-groups {
                                  .form-group {
                                    label {
                                      min-width: auto;
                                    }
                                  }
                                }
                              }
                              .features-box {
                                .checkboxes {
                                  display: flex;
                                  flex-wrap: wrap;
                                  margin: -7px;
                                  .checkbox-block {
                                    height: 48px;
                                    margin: 7px;
                                    overflow: hidden;
                                    input[type="checkbox"] {
                                      position: absolute;
                                      opacity: 0;
                                      visibility: hidden;
                                    }
                                    input[type="checkbox"]:checked ~ label {
                                      background: #f6f6f6;
                                    }
                                    input[type="checkbox"]:focus {
                                      & ~ label {
                                        i {
                                          &:before {
                                            opacity: 0.5;
                                          }
                                        }
                                      }
                                    }
                                    input[type="checkbox"]:checked
                                      ~ label
                                      i:before {
                                      opacity: 1;
                                    }
                                    label {
                                      border-radius: 4px;
                                      display: flex;
                                      align-items: center;
                                      font-size: 14px;
                                      color: #404040;
                                      cursor: pointer;
                                      position: relative;
                                      flex: none;
                                      user-select: none;
                                      &:hover {
                                        i {
                                          &:before {
                                            opacity: 0.5;
                                          }
                                        }
                                      }
                                      i {
                                        flex: none;
                                        display: block;
                                        width: 22px;
                                        height: 22px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        margin-right: 7px;
                                        border: 1px solid #d1d1d1;
                                        border-radius: 2px;
                                        background: #fff;
                                        &:before {
                                          content: "";
                                          display: block;
                                          @include sprite($ic30);
                                          opacity: 0;
                                        }
                                      }
                                    }
                                    &.need-block {
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.radios {
  display: flex;
  align-items: center;
  height: 28px;
  margin: 0 -2px;
  .radio-box {
    position: relative;
    margin: 0 2px;
    input[type="radio"] {
      position: absolute;
      opacity: 0;
      visibility: hidden;
    }
    input[type="radio"]:checked ~ label {
      background: #3e50aa;
      border-color: #3e50aa;
      color: #fff;
    }
    input[type="radio"]:focus {
      & ~ label {
        i {
          &:before {
            opacity: 0.5;
          }
        }
      }
    }
    input[type="radio"]:checked ~ label i:before {
      opacity: 1;
    }
    label {
      width: 28px;
      height: 28px;
      border-radius: 3px;
      border: 1px solid #d1d1d1;
      background: #fff;
      font-size: 14px;
      color: #404040;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}

.feed-block {
  .column {
    max-width: 530px;
  }
}

.box-body__checkbox {
  display: flex;
  flex-wrap: wrap;

  &.column {
    flex-direction: column;
  }
}

.box-body__label {
  margin-right: 5px;
  margin-bottom: 7px;
  padding: 0 15px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  width: 221px;
  min-height: 28px;
  font-size: 14px;
  //color: №3e3e3e;
  //border: 1px solid transparent;
  border-radius: 4px;
  //background-color: rgb(252, 252, 252);
  cursor: pointer;
  font-family: "MyriadPro-Regular";
}

.label-text {
  display: inline-block;
  padding-left: 10px;

  span:last-child {
    color: #3e3e3e;
  }
}

.box-body__subtitle {
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: "MyriadPro-Regular";
  //font-family: "Myriad Pro";
  color: rgb(62, 62, 62);
}

.checkbox-fake {
  position: relative;
  border: 1px solid rgb(214, 214, 214);
  //border-radius: 50%;
  border-radius: 3px;
  width: 25px;
  height: 25px;

  &:after {
    content: "";
    position: absolute;
    top: -7px;
    left: 1px;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 16px;
    height: 10px;
    border-radius: 1px;
    border-top: 3px solid #455ead;
    border-right: 3px solid #455ead;
    transform: rotate(126deg);
    opacity: 0;
  }
}

.checkbox-fake--circle {
  border-radius: 50%;

  &:after {
    width: 10px;
    height: 10px;
    border: none;
    border-radius: 50%;
    top: 0;
    left: 0;
    background-color: #455ead;
  }
}

.indent {
  margin-left: 30px;
}
.box-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background-color: rgb(235, 235, 235);
  width: 100%;
  height: 79px;

  span {
    font-size: 14px;
    color: #3e3e3e;
    font-family: "MyriadPro-Regular";
  }
}

.label__input:checked + .box-body__label .checkbox-fake:after {
  opacity: 1;
}

.label__input:checked + .box-body__label {
  border-color: rgb(69, 94, 173);
}
