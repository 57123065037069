.package-page {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

.page-header {
	padding: 0 25px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #F5F5F5;

	&.page-header-end {
		justify-content: flex-end;
	}
}

.package {
	display: flex;
	align-items: center;
	font-family: "Roboto";
}

.package__list {
	margin: 0;
	padding: 0;
	display: flex;
	align-items: center;

	li {
		margin-right: 50px;
		list-style-type: none;
	}

	li:last-child {
		color: #202020;
	}
}

.package__subtitle {
	font-size: 14px;
	color: #202020;
	font-weight: bold;

	&.green {
		color: #7b9438;
	}
}

.button-40 {
	height: 40px;
	line-height: 40px;
}

.button-m {
	width: 124px;
}

.button-arrow {
	display: flex;
	align-items: center;

	span:last-child {
		padding-left: 20px;
	}
}

.button-icon {
	position: relative;
	display: inline-block;
	width: 35px;
	height: 100%;
	background-color: #2066cf;


	&:before {
		content: '';
		position: absolute;
		top: calc(50% - 6px);
		left: calc(50% - 6px);
		width: 12px;
		height: 12px;
		border-left: solid 1px #fff;
		border-top: solid 1px #fff;
		transform: rotate(-45deg);
	  }
}

.button-blue {
	font-family: "Roboto";
	font-size: 14px;
	color: #ffffff;
	background-color: #266eda;

	&:hover {
		background-color: #2066cf;
	}
}

.button-l {
	width: 190px;
}

.package__title {
	margin-right: 15px;
	font-size: 18px;
	color: #141414;
	font-weight: bold;
}

.quota-modal,
.package-modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1050;
	display: none;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	outline: 0;
	background-color: rgba(0,0,0,0.5);
	font-family: "Roboto";
}

.quota-modal {
	.modal__dialog {
		width: 100%;
		max-width: 480px;
	}

	.modal__footer {
		margin-top: 20px;
		display: flex;
		align-items: center;
	}

	.form__select.disabled {
		pointer-events: none;
		color: #ccc;
	}

	.form__label.disabled {
		color: #ccc;
	}
}

.package-modal {
	.modal__dialog {
		width: 100%;
		max-width: 820px;
	}

	.form__group-lg {
		width: 90%;

		&:first-child {
			margin-right: 20px;
		}
	}

	.form__group-m {
		width: 348px;
	}

	.modal__footer {
		margin-top: 20px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

}

.date-created {
	margin-right: 15px;
	font-size: 14px;
	color: #929292;
}

.dateinput-wrapper {
	position: relative;

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 40px;
		height: 40px;
		background: url('../images/ic27.png') 13px center no-repeat;
	}

	.form__input {
		padding-left: 50px;
		width: 100%;
	}
}

@media screen and (max-width: 1600px) {
  .package-page .page-header {
    min-width: 1680px;
  }
}