.submodule__docs {
  padding-bottom: 150px;
  .docs__download {
    margin-left: auto;
    margin-right: 30px;
  }
  &.dragenter{
    border: 3px solid cornflowerblue;
  }
}
.docs__download--filled {
  background-color: #b0bbdf;

  &:hover {
    background-color: #fff;
  }
}

.docs__download--lg {
  width: 179px;
}