.tasks-page {
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	overflow-x: auto;
	.page-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 58px;
		box-shadow: 0px 0px 11px 0px rgba(226, 227, 231, 0.49);
		border-bottom: 1px solid #F5F5F5;
		flex: none;
		width: 100%;
		// Верхний прогресс-бар
		.progress-wrapper {
			display: flex;
			align-items: center;
			.name {
				font-size: 14px;
				color: #424242;
				padding: 0 22px;
			}
			.progress-bar {
				background: #E6E6E6;
				box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.19);
				width: 740px;
				height: 11px;
				border-radius: 25px;
				position: relative;
				.line {
					background: linear-gradient( 91deg, rgb(134,156,238) 0%, rgb(138,181,245) 100%);
				  	box-shadow: 0px 3px 6px 0px rgba(134, 156, 238, 0.42);
					border-radius: 25px;
					position: absolute;
					left: 0;
					top: 0;
					height: 100%;
					transition: width 1s;
					.num {
						position: absolute;
						right: 0;
						bottom: 100%;
						font-size: 11px;
						color: #424242;
						margin-bottom: 4px;
					}
				}
			}
		}
		//
		.filters {
			display: flex;
			padding-right: 15px;
			.filter-wrapper {
				display: flex;
				align-items: center;
				margin: 0 10px;
				.name {
					font-size: 14px;
					color: #303030;
					margin-right: 10px;
				}
				select,
				input {
					border: 1px solid #999999;
					height: 40px;
					color: #534c4c;
					font-size: 12px;
					outline: none;
					border-radius: 4px;
				}
				select {
					width: 166px;
					color: #534c4c;
					font-size: 12px;
					padding: 0 13px;
				}
				.dateinput-wrapper {
					background: url(../images/ic27.png) 13px center no-repeat;
					input {
						width: 140px;
						background: transparent;
						padding: 0 15px;
						padding-left: 50px;
						font-size: 14px;
						cursor: pointer;
					}
				}
			}
		}
	}
	.page-common {
		flex: 1 1 auto;
		display: flex;
		background: #FFFFFF;
		position: relative;
		width: 100%;
		&.open-task-modal {
			.task-modal-wrapper {
				opacity: 1;
				visibility: visible;
				transition: opacity 0.3s ease;
				.modal {
					transform: translateX(0);
				}
			}
		}
		.task-modal-wrapper {
			opacity: 0;
			visibility: hidden;
			background: rgba(#2D2D2D, 0.55);
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			z-index: 6;
			display: flex;
			justify-content: flex-end;
			transition: opacity 0.3s ease, visibility 0s ease 0.3s;
			.modal {
				max-width: 663px;
				width: 100%;
				background: #fff;
			    border-top: 2px solid #91B7F4;
			    transform: translateX(100%);
			    display: flex;
			    transition: all 0.3s ease;
				.modal-container {
					flex-direction: column;
					justify-content: space-between;
					display: flex;
					flex: 1 1 auto;
				}
			    .modal-top {
			    	overflow: auto;
			    	.modal-top_head {
			    		display: flex;
			    		align-items: center;
			    		height: 53px;
			    		background: #F5F5F5;
			    		padding-left: 5px;
			    		.responsible-block {
			    			position: relative;
			    			padding: 0 12px;
			    			&.open {
			    				.custom-select-dropdown {
			    					transform: translateY(0);
			                        visibility: visible;
			                        opacity: 1;
			                        transition: opacity .2s ease, transform .2s ease;
			    				}
			    			}
			    			.custom-select-selected {
			    				display: flex;
			    				align-items: center;
			    				padding: 0 5px;
			    				// border: 1px solid transparent;
			    				border-radius: 25px;
			    				height: 40px;
			    				cursor: pointer;
			    				// box-shadow: 0px 0px 0px 0px rgba(#909FF0, 0);
			    				transition: .2s box-shadow;
			    				&:hover {
			    					box-shadow: 0px 0px 0px 2px rgba(#909ff0, 1);
			    					// border: 1px solid #D8D8D8;
			    				}
			    				i {
			    					display: block;
			    					width: 30px;
			    					height: 30px;
			    					background: #E7E7E7;
			    					border-radius: 100%;
			    					overflow: hidden;
			    					.ic {
			    						font-size: 14px;
			    						color: #787f81;
			    						width: 30px;
			    						height: 30px;
			    						display: flex;
			    						justify-content: center;
			    						align-items: center;
			    						font-style: normal;
			    					}
			    				}
			    				.name,
			    				span {
			    					font-size: 14px;
			    					color: #383838;
			    					overflow: hidden;
			    					text-overflow: ellipsis;
			    					white-space: nowrap;
			    					padding: 0 12px;
			    				}
		                        .image {
		                            width: 30px;
		                            height: 30px;
		                            border-radius: 100%;
		                            overflow: hidden;
		                            flex: none;
		                            img {
		                                display: block;
		                                width: 100%;
		                            }
		                        }
			    			}
			    			.custom-select-dropdown {
			                    position: absolute;
			                    top: 100%;
			                    margin-top: 4px;
			                    left: 0;
			                    width: 100%;
			                    max-height: 228px;
			                    overflow-y: auto;
			                    z-index: 5;
			                    background: #fff;
			                    opacity: 0;
			                    box-shadow: 0px 2px 10px 0px rgba(#000, 0.1);
			                    visibility: hidden;
			                    transform: translateY(4px);
			                    transition: visibility 0s ease .2s, opacity .2s ease, transform .2s ease;
			                    &:hover {
			                        .mCSB_scrollTools {
			                            opacity: 1;
			                        }
			                        .mCSB_dragger_bar {
			                        	background: #4F5667;
			                        }
			                    }
			                    .mCSB_scrollTools {
			                        opacity: 0.4;
			                    }
			                    .mCSB_dragger_bar {
			                        background: #DADADA;
			                        width: 6px;
			                    }
			                    .mCSB_draggerRail {
			                        background: #EBEBEB;
			                        width: 2px;
			                    }
			                    .custom-select-option {
			                        cursor: pointer;
			                        padding: 4px 10px;
			                        font-size: 12px;
			                        display: flex;
			                        align-items: center;
			                        font-weight: 300;
			                        color: #000;
			                        span {
			                            flex: 1 1 auto;
			                            white-space: nowrap;
			                            overflow: hidden;
			                            font-size: 12px;
			                            margin-right: 0;
			                            color: #000;
			                            text-overflow: ellipsis;
			                        }
			                        &.active {
			                            background: #EAEAEA;
			                            cursor: default;
			                            pointer-events: none;
			                            display: none;
			                        }
			                        &:hover {
			                            background: #F8F8F8;
			                        }
			                        .image {
			                            width: 30px;
			                            height: 30px;
			                            border-radius: 100%;
			                            overflow: hidden;
			                            margin-right: 10px;
			                            flex: none;
			                            img {
			                                display: block;
			                                width: 100%;
			                            }
			                        }
			                    }
			                }
			    		}
			    		.date {
			    			display: flex;
			    			align-items: center;
			    			margin-left: 15px;
			    			i {
			    				display: block;
			    				@include sprite($ic22);
			    				flex: none;
			    			}
			    			.name {
			    				font-size: 14px;
			    				color: #2f2f2f;
			    				margin-left: 12px;
			    			}
			    		}
			    	}
			    	.modal-top_dophead {
			    		display: flex;
			    		align-items: center;
			    		height: 65px;
			    		border-bottom: 1px solid #E8E8E8;
			    		.project {
			    			color: #1b1b1b;
				    		font-size: 12px;
				    		padding: 0 22px;
				    		height: 30px;
				    		border-radius: 0px 3px 3px 0px;
				    		display: flex;
				    		align-items: center;
			    		}
			    		select {
							border-radius: 3px;
							min-width: 133px;
							height: 32px;
							border: 1px solid #EDEFEF;
							padding: 0 15px;
							font-size: 12px;
							color: #454545;
							outline: none;
							display: block;
							margin-left: 22px;
			    		}
			    	}
			    	.task-name-desc {
			    		padding: 22px 22px 22px 18px;
			    		.task-name-wrapper {
			    			position: relative;
			    			&:hover {
								textarea {
									border: 1px solid #EDEFEF;
								}
							}
			    			.complete-task-button {
			    				cursor: pointer;
			    				position: absolute;
			    				left: 6px;
			    				top: 6px;
			    				z-index: 3;
			    				width: 30px;
			    				height: 30px;
			    				border-radius: 100%;
			    				box-shadow: 0px 0px 0px 0px rgba(#68C600, 0);
			    				transition: box-shadow 0.3s ease;
			    				svg {
			    					path {
			    						transition: all 0.3s ease;
			    					}
			    				}
			    				&:hover, &.completed {
									box-shadow: 0px 0px 2px 1px rgba(#68C600, 1);
									svg {
										path {
											fill: #68C600;
										}
									}
			    				}
			    			}
			    			.task-name-block,
			    			textarea {
			    				font-size: 18px;
			    				border: 1px solid transparent;
			    				padding: 10px 15px 9px 47px;
		    				    word-break: break-word;
	    				        white-space: pre-wrap;
			    			}
			    			.task-name-block {
			    				min-height: 42px;
			    				color: #fff;
			    			}
			    			textarea {
			    				position: absolute;
			    				width: 100%;
			    				height: 100%;
			    				left: 0;
			    				top: 0;
			    				border-radius: 3px;
								display: block;
								border: 1px solid transparent;
								outline: none;
								resize: none;
								color: #151515;
								overflow: hidden;
								z-index: 2;
								@include input-placeholder {
									color: #BABABA
								}
								&:focus {
									border: 1px solid #D8D8D8;
								}
			    			}
			    		}
			    		.task-desc-container {
			    			padding-left: 39px;
			    			padding-top: 8px;
							.task-desc-wrapper {
				    			position: relative;
				    			&:hover {
									textarea {
										border: 1px solid #EDEFEF;
									}
								}
				    			.task-desc-block,
				    			textarea {
				    				font-size: 14px;
				    				border: 1px solid transparent;
				    				padding: 5px 9px;
			    				    word-break: break-word;
		    				        white-space: pre-wrap;
				    			}
				    			.task-desc-block {
				    				min-height: 50px;
				    				color: #fff;
				    			}
				    			textarea {
				    				position: absolute;
				    				width: 100%;
				    				height: 100%;
				    				left: 0;
				    				top: 0;
				    				border-radius: 3px;
									display: block;
									border: 1px solid transparent;
									outline: none;
									resize: none;
									color: #1f1f1f;
									overflow: hidden;
									z-index: 2;
									@include input-placeholder {
										color: #D2D2D2
									}
									&:focus {
										border: 1px solid #D8D8D8;
									}
				    			}
				    		}
			    		}
			    	}
			    	.subtasks-module {
			    		.subtask-item {
			    			display: flex;
			    			padding: 0 22px 0 66px;
			    			border-bottom: 1px solid #E8E8E8;
			    			border-top: 1px solid #E8E8E8;
			    			cursor: text;
			    			&.focus {
			    				border-color: #91B7F4 !important;
			    				position: relative;
			    				background: #E9F1FF;
			    				.subtask-common {
			    					textarea {
			    						background: #E9F1FF;
			    					}
			    				}
			    			}
			    			&:not(:first-child) {
			    				margin-top: -1px;
			    			}
			    			&:hover {

							}
							.btn-wrapper {
								flex: none;
								width: 27px;
								padding-top: 15px;
								.complete-task-button {
				    				position: relative;
				    				z-index: 2;
				    				cursor: pointer;
				    				width: 20px;
				    				height: 20px;
				    				border-radius: 100%;
				    				box-shadow: 0px 0px 0px 0px rgba(#68C600, 0);
				    				border: 1px solid #888888;
				    				transition: 200ms box-shadow,200ms color,200ms background,200ms fill;
				    				flex: none;
				    				display: flex;
				    				justify-content: center;
				    				align-items: center;
				    				svg {
				    					height: 10px;
	    								width: 10px;
	    								fill: #888888;
				    					polygon {
				    						width: 20px;
				    						height: 20px;
				    						transition: all 0.3s ease;
				    					}
				    				}
				    				&:hover {
									    background-color: #fff;
									    border-color: #fff;
									    box-shadow: 0 0 0 3px #68C600;
									    color: #3bf7d1;
									    fill: #3bf7d1;
				    				}
				    			}
							}
			    			.subtask-common {
			    				position: relative;
			    				flex: 1 1 auto;
								.subtask-name-block,
				    			textarea {
				    				font-size: 14px;
				    				border: 1px solid transparent;
				    				padding: 16px 0;
			    				    word-break: break-word;
		    				        white-space: pre-wrap;
				    			}
				    			.subtask-name-block {
				    				min-height: 42px;
				    				color: #fff;
				    			}
				    			textarea {
				    				position: absolute;
				    				width: 100%;
				    				height: 100%;
				    				left: 0;
				    				top: 0;
				    				border-radius: 3px;
									display: block;
									border: 1px solid transparent;
									outline: none;
									resize: none;
									color: #787f81;
									overflow: hidden;
									z-index: 2;
									@include input-placeholder {
										color: #787f81
									}
				    			}
			    			}
			    		}
			    	}
			    	.task-history-list {
			    		margin-top: -1px;
						.day-history {
							border-bottom: 1px solid #E8E8E8;
							padding: 15px 0;
							&:first-child {
								border-top: 1px solid #E8E8E8;
							}
							&:last-child {
								border-bottom: 0;
							}
							.list-item {
								font-size: 12px;
								color: #929292;
								padding: 0 22px 0 66px;
								margin: 5px 0;
								position: relative;
								&.complete {
									color: #8cc836;
									padding: 8px 22px 8px 66px;
									margin: 15px 0;
									&::before {
										content: '';
										display: block;
										position: absolute;
										left: 28px;
										top: 0;
										@include sprite($task-complete)
									}
									a {
										color: #8cc836;
										&:hover {
											color: #9AD842;
											text-decoration: underline;
										}
									}
									.date {
										color: #929292;
									}
								}
								a {
									// color: #585858;
									color: #929292;
									text-decoration: none;
									font-weight: 600;
									&:hover {
										color: #91B7F4;
									}
								}
								.date {
									margin-left: 12px;
								}
							}
						}
			    	}
			    	.comments-module {
			    		.head {
			    			display: flex;
			    			justify-content: space-between;
			    			align-items: center;
			    			padding: 0 22px 0 66px;
			    			margin-bottom: 20px;
			    			.name {
			    				font-size: 20px;
			    				color: #787f81;
			    			}
			    			.show-more-comments {
			    				font-size: 13px;
			    				color: #787f81;
			    				display: flex;
			    				align-items: center;
			    				cursor: pointer;
			    				&:hover {
			    					color: #91B7F4;
			    				}
			    				i {
			    					display: block;
			    					flex: none;
			    					@include sprite($ic23);
			    					margin-left: 12px;
			    				}
			    			}
			    		}
			    		.comments-list {
			    			.comment-item {
			    				display: flex;
			    				padding: 0 22px 0 28px;
			    				margin: 17px 0;
			    				&:hover {
			    					.common {
			    						.top {
			    							.remove-comment {
			    								opacity: 1;
			    							}
			    						}
			    					}
			    				}
			    				.ava {
			    					width: 30px;
			    					height: 30px;
			    					border-radius: 100%;
			    					overflow: hidden;
			    					flex: none;
			    					margin-right: 9px;
			    					img {
			    						display: block;
			    						width: 100%;
			    					}
			    				}
			    				.common {
			    					flex: 1 1 auto;
			    					.top {
			    						display: flex;
			    						align-items: center;
			    						justify-content: space-between;
			    						margin-bottom: 5px;
			    						.info {
			    							display: flex;
			    							align-items: center;
			    							.name {
			    								font-size: 12px;
			    								color: #585858;
			    								text-decoration: none;
			    								font-weight: 600;
			    								&:hover {
			    									color: #464646;
			    									text-decoration: underline;
			    								}
			    							}
			    							.date {
			    								font-size: 12px;
			    								color: #a2a2a2;
			    								margin-left: 12px;
			    							}
			    						}
			    						.remove-comment {
			    							color: #a2a2a2;
			    							font-size: 12px;
			    							display: flex;
			    							align-items: center;
			    							cursor: pointer;
			    							opacity: 0;
			    							i {
			    								display: block;
			    								flex: none;
			    								margin-left: 5px;
			    								@include sprite($ic24)
			    							}
			    						}
			    					}
			    					.text {
			    						font-size: 12px;
			    						color: #585858;
			    						line-height: 1.4em;
			    					}
			    				}
			    			}
			    		}
			    	}
			    }
			    .comment-create-block {
			    	border-top: 1px solid #DFE0E5;
			    	flex: none;
			    	background: #F5F5F5;
			    	display: flex;
			    	padding: 10px 22px;
			    	padding-left: 29px;
			    	.ava {
			    		width: 30px;
			    		height: 30px;
			    		flex: none;
			    		overflow: hidden;
			    		border-radius: 100%;
			    		margin-right: 9px;
			    		img {
			    			display: block;
			    			width: 100%;
			    		}
			    	}
			    	.comment-create-common {
						position: relative;
						flex: 1 1 auto;
						overflow: hidden;
						max-height: 200px;
						border: 1px solid #DFE0E5;
						border-radius: 5px;
						display: flex;
						flex-direction: column;
						&.focus {
							// max-height: 200px;
							.comment-create-text-block,
		    				textarea {
								min-height: 80px;
		    				}
		    				.button-wrapper {
		    					margin-bottom: 0;
		    				}
						}
		    			&:hover {
							textarea {
								// border: 1px solid #D6D8E0;
							}
						}
						.text-block {
							position: relative;
							// flex: 1 1 auto;
							overflow: hidden;
							max-height: 145px;
						}
		    			.comment-create-text-block,
		    			textarea {
		    				font-size: 12px;
		    				border: 1px solid transparent;
		    				padding: 7px 9px;
	    				    word-break: break-word;
    				        white-space: pre-wrap;
    				        transition: min-height 0.3s ease;
		    			}
		    			.comment-create-text-block {
		    				min-height: 30px;
		    				color: #fff;
		    				border-radius: 5px;
		    				flex: 1 1 auto;
		    			}
		    			textarea {
		    				position: absolute;
		    				width: 100%;
		    				height: 100%;
		    				left: 0;
		    				top: 0;
		    				border-radius: 3px;
							display: block;
							// border: 1px solid #DFE0E5;
							outline: none;
							resize: none;
							color: #1f1f1f;
							// overflow: hidden;
							overflow-y: auto;
							z-index: 2;
							border-radius: 5px;
							background: #fff;
							@include input-placeholder {
								color: #aaabb1
							}
							&:focus {
								// border: 1px solid #B9BDCB;
							}
		    			}
		    			.button-wrapper {
		    				margin-bottom: -51px;
		    				padding: 11px 18px;
		    				display: flex;
		    				justify-content: flex-end;
		    				transition: margin-bottom 0.3s ease;
		    				background: #f7f7f7;
		    				border-top: 1px solid #E2E2E2;
		    				flex: none;
		    				button {
								border-radius: 3px;
								background-color: #60A572;
								width: 112px;
								height: 29px;
								color: #fff;
								border: none;
								outline: none;
								cursor: pointer;
								font-size: 12px;
		    				}
							.comment-button {
								border-radius: 3px;
								background-color: #60A572;
								width: 112px;
								height: 29px;
								color: #fff;
								border: none;
								outline: none;
								cursor: pointer;
								font-size: 12px;
								text-align: center;
								vertical-align: middle;
								line-height: 30px;
							}
		    			}
			    	}
			    }
			}
		}
		.tasks-list-wrapper {
			overflow-y: hidden;
			display: flex;
			flex: 1 1 auto;
			user-select: none;
			.tasks-list {
				display: flex;
				flex: 1 1 auto;
				width: calc(-150vw);
				.tasks-column {
					width: 274.285714px;
					border-left: 1px solid #EFEFEF;
					display: flex;
					flex-direction: column;
					flex: none;
					&:last-child {
						border-right: 1px solid #EFEFEF;
					}
					&:nth-child(odd) {
						background: #FDFDFD;
					}
					.column-head {
						flex: none;
						.column-day {
							padding: 0 18px;
							display: flex;
							align-items: center;
							font-size: 13px;
							color: #2a2a2a;
							height: 33px;
							border-bottom: 1px solid #EFEFEF;
							position: relative;
							overflow: hidden;
							.progress-bar {
								position: absolute;
								left: 0;
								top: 0;
								width: 100%;
								height: 100%;
								z-index: 1;
								opacity: 0.5;
								background: #596eff;
								transition: 0.2s ease;
							}
							span {
								position: relative;
								z-index: 2;
							}
						}
						.column-create-block {
							position: relative;
							border-bottom: 1px solid #EFEFEF;
							// overflow: hidden;
							&.open {
								.default {
									// transform: translateY(-100%);
									// opacity: 0;
								}
								input {
									// transform: translateY(0);
									opacity: 1;
									visibility: visible;
									transition: transform 0.3s ease, opacity 0.3s ease, visibility 0s ease 0s;
								}
							}
							&.open {
								.select-block {
									display: block;
								}
							}
							&.open-select {
								&.open-help {
									.help-select-block {
										display: block;
									}
								}
							}
							.default {
								display: flex;
								justify-content: space-between;
								align-items: center;
								padding: 0 0 0 18px;
								cursor: text;
								opacity: 1;
								transition: all 0.3s ease;
								.name-create-block {
									font-size: 13px;
									color: #939393;
									flex: 1 1 auto;
									height: 39px;
									display: flex;
									align-items: center;
								}
								.button {
									background: url(../images/ic28.png) center center no-repeat;
								    width: 39px;
    								height: 39px;
    								cursor: pointer;
    								transition: all 0.3s ease;
    								&:hover {
    									opacity: .7;
    								}
								}
							}
							input {
								position: absolute;
								left: 0;
								top: 0;
								width: 100%;
								height: 100%;
								display: block;
								outline: none;
								padding: 0 18px;
								font-size: 13px;
								color: #343434;
								border: none;
								opacity: 0;
								visibility: hidden;
								// transform: translateY(100%);
								transition: transform 0.3s ease, opacity 0.3s ease, visibility 0s ease 0.3s;
								@include input-placeholder {
									color: #939393;
								}
							}
							.select-block {
								box-shadow: 0 4.5px 8px 0px rgba(89, 114, 205, 0.14);
								position: absolute;
								left: 0 !important;
								top: 40px !important;
								width: 100%;
								z-index: 5;
								background: #fff;
								max-height: 300px;
								overflow-y: auto;
								padding: 0;
								margin: 0;
								display: none;
								.select-block-item, .textcomplete-item {
									border-bottom: 1px solid #EFEFEF;
									font-size: 11px;
									color: #2a2a2a;
									display: flex;
									align-items: center;
									overflow: hidden;
									padding: 12px;
									cursor: pointer;
									min-height: 52px;
									&:hover, &.active {
										background: #a7b0dc;
										.ava {
											border: 1px solid #fff;
										}
										.name {
											color: #fff;
										}
									}
									.ava {
										border-radius: 100%;
										overflow: hidden;
										margin-right: 12px;
										flex: none;
										border: 1px solid #EFEFEF;
										img {
											display: block;
											width: 100%;
										}
									}
									.name {
										white-space: normal;
										text-overflow: ellipsis;
										overflow: hidden;
									}
								}
							}
							.help-select-block {
								box-shadow: 0 4.5px 8px 0px rgba(89, 114, 205, 0.14);
								position: absolute;
								left: 0;
								top: 40px;
								width: 100%;
								z-index: 5;
								background: #fff;
								display: none;
								max-height: 300px;
								overflow-y: auto;
								padding: 0;
								margin: 0;
								.select-block-item {
									border-bottom: 1px solid #EFEFEF;
									font-size: 11px;
									color: #2a2a2a;
									display: flex;
									align-items: center;
									overflow: hidden;
									padding: 12px;
									cursor: pointer;
									min-height: 52px;
									&:hover, &.active {
										background: #A7B0DC;
										.ava {
											border: 1px solid #fff;
										}
										.name {
											color: #fff;
										}
									}
									.name {
										display: flex;
										align-items: center;
										white-space: normal;
										text-overflow: ellipsis;
										overflow: hidden;
										span {
											background-color: #acb7ff;
											padding: 2px 7px;
											border-radius: 4px;
											margin-right: 9px;
											display: flex;
										}
									}
								}
							}
						}
					}
					.column-body-wrapper {
						flex: 1 1 auto;
						overflow-y: hidden !important;
						&:hover {
							.mCSB_dragger_bar {
								background: #000 !important;
							}
						}
						.mCSB_container {
							margin-right: 0;
						}
						.mCSB_scrollTools {
							opacity: 0.5;
							z-index: 3;
							width: 1px;
							right: 1px;
							z-index: 3;
							margin: 0;
							.mCSB_draggerContainer {
								.mCSB_dragger {
									.mCSB_dragger_bar {
										background: #A6A6A6;
										width: 2px;
										transition: background .2s ease;
										-webkit-transition: background .2s ease;
										margin: 0;
									}
								}
								.mCSB_draggerRail {
									background: transparent;
									width: 1px;;
								}
							}
						}
					}
					.column-body {
						flex: 1 1 auto;
					    min-height: calc(100vh - 231px);
						.task-item-placeholder {
							background: transparent;
							border: none;
							background: #F9F9F9;
							border-bottom: 1px solid #EFEFEF;
						}
						.task-item {
							border-bottom: 1px solid #EFEFEF;
							position: relative;
							background: #fff;
							cursor: pointer;
							overflow: hidden;
							transition: box-shadow 0.3s ease, background 0.3s ease, transform 0.2s ease;
							box-shadow: 0px 0px 0px 0px rgba(#000, 0);
							&:hover {
								background: #F4F8FF;
								box-shadow: 0px 0px 15px 0px rgba(#000, 0.1);
								z-index: 2;
								transform: scale(1.02);
								.controls {
									transform: translateX(0);
									opacity: 1;
								}
							}
							&.moving {
								z-index: 3;
								transform: scale(1.01);
							    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
							}
							&.ghost {
								opacity: 0.3;
							}
							.complete-task-button {
								cursor: pointer;
								//position: absolute;
								//left: 6px;
								//top: 6px;
								//z-index: 3;
								width: 20px;
								height: 20px;
								border-radius: 100%;
								box-shadow: 0px 0px 0px 0px rgba(#68C600, 0);
								transition: box-shadow 0.3s ease;
								svg {
									path {
										transition: all 0.3s ease;
									}
								}
								&:hover, &.completed {
									box-shadow: 0px 0px 2px 1px rgba(#68C600, 1);
									svg {
										path {
											fill: #68C600;
										}
									}
								}
							}
							// Controls v2
							.controls {
								height: 100%;
								width: 100%;
								position: absolute;
								right: 0;
								z-index: 1;
								display: flex;
								transform: translateX(101%);
								opacity: 0;
								transition: 0.35s ease;
								.button {
									display: flex;
									justify-content: center;
									align-items: center;
									flex: 1 1 auto;
									cursor: pointer;
									opacity: .7;
									transition: all .2s ease;
									&:hover {
										opacity: .8;
									}
									&.complete {
										background: linear-gradient(to right, #4BA100 0%, #50CC00 100%);
										flex: none;
										width: 48px;
										&::before {
											content: '';
											display: block;
											background: url(../images/complete.svg) center center no-repeat;
											width: 20px;
											height: 15px;
										}
									}
									&.edit {
										flex: 1 1 auto;
										background: linear-gradient(to right, transparent 0%, rgb(33,77,155) 100%);
										padding-right: 14px;
										justify-content: flex-end;
										&::before {
											content: '';
											display: block;
											background: url(../images/edit.svg) center center no-repeat;
											width: 8px;
											height: 24px;
										}
									}
								}
							}

							// Controls v1
							&.open-controls {
								// .controls {
								// 	opacity: 1;
								// 	visibility: visible;
								// 	transition: opacity 0.3s ease;
								// 	.button {
								// 		transform: none;
								// 		transition: transform 0.2s cubic-bezier(0.47, 0.48, 0, 1.74), opacity 0.3s ease;
								// 	}
								// }
							}
							// .controls {
							// 	position: absolute;
							// 	left: 0;
							// 	width: 100%;
							// 	height: 62px;
							// 	background: linear-gradient(to bottom, transparent 0%, rgba(81,93,114,0.3) 100%);
							// 	display: flex;
							// 	justify-content: center;
							// 	align-items: center;
							// 	z-index: 2;
							// 	padding: 0 25px;
							// 	opacity: 0;
							// 	visibility: hidden;
							// 	transition: opacity 0.3s ease, visibility 0s ease 0.3s;
							// 	bottom: 100%;
							// 	.button {
							// 		width: 43px;
							// 		height: 43px;
							// 		border-radius: 100%;
							// 		display: flex;
							// 		justify-content: center;
							// 		align-items: center;
							// 		transform: scale(0);
							// 		transition:all 0.3s ease;
							// 		cursor: pointer;
							// 		margin: 0 20px;
							// 		&:hover {
							// 			// transform: scale(1.1);
							// 			transition: all 0.2s ease;
							// 			transition-delay: 0s !important;
							// 		}
							// 		&:nth-child(1) {
							// 			transition-delay: 0s;
							// 			&:hover {
							// 				box-shadow: 0px 1px 3px 0px rgba(85, 144, 10, 0.51);
							// 			}
							// 		}
							// 		&:nth-child(2) {
							// 			transition-delay: 0.08s;
							// 			&:hover {
							// 				box-shadow: 0px 1px 3px 0px rgba(85, 144, 10, 0.51);
							// 			}
							// 		}
							// 		&:nth-child(3) {
							// 			transition-delay: 0.16s;
							// 			&:hover {
							// 				box-shadow: 0px 1px 3px 0px rgba(85, 144, 10, 0.51);
							// 			}
							// 		}
							// 		&::before {
							// 			content: '';
							// 			display: block;
							// 		}
							// 		&.complete {
							// 			background: #55B247;
							// 			box-shadow: 0px 3px 7px 0px rgba(85, 144, 10, 0.51);
							// 			&::before {
							// 				background: url(../images/complete.svg) center center no-repeat;
							// 				width: 43px;
							// 				height: 10px;
							// 			}
							// 		}
							// 		&.edit {
							// 			background: #006299;
							// 			box-shadow: 0px 3px 7px 0px rgba(15, 86, 153, 0.51);
							// 			&::before {
							// 				background: url(../images/edit.svg) center center no-repeat;
							// 				width: 43px;
							// 				height: 21px;
							// 			}
							// 		}
							// 		&.remove {
							// 			background: #B85856;
							// 			box-shadow: 0px 3px 7px 0px rgba(161, 16, 49, 0.51);
							// 			&::before {
							// 				background: url(../images/remove.svg) center center no-repeat;
							// 				width: 43px;
							// 				height: 16px;
							// 			}
							// 		}
							// 	}
							// }
							&.task-complete {
								background: #FBFBFB;
								cursor: default;
								.top {
									.name {
										color: #c2c2c2;
										text-decoration: line-through;
									}
								}
								.bottom {
									color: #d9d9d9;
									.project {
										.project-color {
											opacity: 0.5;
										}
									}
								}
							}
							&.hot {
								&::before {
									content: '';
									display: block;
									position: absolute;
									right: 8px;
									top: 0;
									border: 1px solid #E7241D;
									background-color: #FF423D;
									box-shadow: 0px 0px 4.7px 0.3px rgba(230, 52, 52, 0.48);
									width: 22px;
									height: 5px;
								    border-radius: 0px 0px 4px 4px;
								}
							}
							.top {
								width: 100%;
								display: flex;
								padding: 15px 8px 0 18px;
								.name {
									flex: 1 1 auto;
									font-size: 13px;
									color: #2a2a2a;
									line-height: 1.3em;
									padding-right: 8px;
								}
								.icon-type {
									flex: none;
									width: 24px;
									display: flex;
									justify-content: center;
									margin-top: 5px;
									&::before {
										content: '';
										display: block;
									}
									&.type-call {
										&::before {
											@include sprite($ic25)
										}
									}
									&.type-meet {
										&::before {
											@include sprite($ic26)
										}
									}
									&.type-action {
										height: 7px;
										border-radius: 25px;
										border: 1px solid rgb(209, 209, 209);
										box-shadow: 0px 0px 4.7px 0.3px rgba(215, 215, 215, 0.48);
										width: 22px;
										&::before {
											display: none;
										}
									}
									&.type-complete {
										height: 7px;
										border-radius: 25px;
										background: #E5FFD6;
										border: 1px solid #C3EEAC;
										box-shadow: 0px 0px 4.7px 0.3px rgba(#C3EEAC, 0.48);
										width: 22px;
										&::before {
											display: none;
										}
									}
								}
							}
							.bottom {
								display: flex;
								justify-content: space-between;
								align-items: center;
								font-size: 10px;
								color: #858585;
								padding: 10px 8px 10px 0;
								.project {
									position: relative;
									padding-left: 18px;
									.project-color {
										box-shadow: inset 0px 0px 0.58px 1.42px rgba(0, 0, 1, 0.09);
										position: absolute;
										left: 0;
										top: 50%;
										margin-top: -3px;
										width: 15px;
										height: 6px;
										border-radius: 0px 25px 25px 0px;
									}
								}
								.right {
									display: flex;
									align-items: center;
									flex: none;
									.date {
										flex: none;
										margin-left: 15px;
									}
									.button {
										&.complete {
											width: 22px;
											height: 22px;
											flex: none;
											cursor: pointer;
											margin-left: 8px;
											transition: .2s ease;
											path {
												fill: #CACACA;
												transition: .2s ease;
											}
											&:hover {
												opacity: 1;
												transform: scale(1.1);
												path {

													fill: #68C600;
												}
											}
										}
									}
								}
							}
						}
						.overdue-color{
							background-color: #FFD;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1600px) {
  .tasks-page .page-common, .tasks-page .page-header {
    width: 1685px;
  }
}