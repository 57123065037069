/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$ava-name: 'ava';
$ava-x: 250px;
$ava-y: 121px;
$ava-offset-x: -250px;
$ava-offset-y: -121px;
$ava-width: 40px;
$ava-height: 40px;
$ava-total-width: 460px;
$ava-total-height: 287px;
$ava-image: '../images/sprite.png?v=1514188540';
$ava: (250px, 121px, -250px, -121px, 40px, 40px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ava', );
$ic1-name: 'ic1';
$ic1-x: 442px;
$ic1-y: 161px;
$ic1-offset-x: -442px;
$ic1-offset-y: -161px;
$ic1-width: 16px;
$ic1-height: 16px;
$ic1-total-width: 460px;
$ic1-total-height: 287px;
$ic1-image: '../images/sprite.png?v=1514188540';
$ic1: (442px, 161px, -442px, -161px, 16px, 16px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic1', );
$ic10-name: 'ic10';
$ic10-x: 357px;
$ic10-y: 187px;
$ic10-offset-x: -357px;
$ic10-offset-y: -187px;
$ic10-width: 20px;
$ic10-height: 20px;
$ic10-total-width: 460px;
$ic10-total-height: 287px;
$ic10-image: '../images/sprite.png?v=1514188540';
$ic10: (357px, 187px, -357px, -187px, 20px, 20px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic10', );
$ic11-name: 'ic11';
$ic11-x: 404px;
$ic11-y: 121px;
$ic11-offset-x: -404px;
$ic11-offset-y: -121px;
$ic11-width: 27px;
$ic11-height: 23px;
$ic11-total-width: 460px;
$ic11-total-height: 287px;
$ic11-image: '../images/sprite.png?v=1514188540';
$ic11: (404px, 121px, -404px, -121px, 27px, 23px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic11', );
$ic12-name: 'ic12';
$ic12-x: 431px;
$ic12-y: 121px;
$ic12-offset-x: -431px;
$ic12-offset-y: -121px;
$ic12-width: 27px;
$ic12-height: 23px;
$ic12-total-width: 460px;
$ic12-total-height: 287px;
$ic12-image: '../images/sprite.png?v=1514188540';
$ic12: (431px, 121px, -431px, -121px, 27px, 23px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic12', );
$ic13-name: 'ic13';
$ic13-x: 284px;
$ic13-y: 209px;
$ic13-offset-x: -284px;
$ic13-offset-y: -209px;
$ic13-width: 15px;
$ic13-height: 18px;
$ic13-total-width: 460px;
$ic13-total-height: 287px;
$ic13-image: '../images/sprite.png?v=1514188540';
$ic13: (284px, 209px, -284px, -209px, 15px, 18px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic13', );
$ic14-name: 'ic14';
$ic14-x: 274px;
$ic14-y: 161px;
$ic14-offset-x: -274px;
$ic14-offset-y: -161px;
$ic14-width: 24px;
$ic14-height: 24px;
$ic14-total-width: 460px;
$ic14-total-height: 287px;
$ic14-image: '../images/sprite.png?v=1514188540';
$ic14: (274px, 161px, -274px, -161px, 24px, 24px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic14', );
$ic15-name: 'ic15';
$ic15-x: 272px;
$ic15-y: 187px;
$ic15-offset-x: -272px;
$ic15-offset-y: -187px;
$ic15-width: 22px;
$ic15-height: 22px;
$ic15-total-width: 460px;
$ic15-total-height: 287px;
$ic15-image: '../images/sprite.png?v=1514188540';
$ic15: (272px, 187px, -272px, -187px, 22px, 22px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic15', );
$ic16-name: 'ic16';
$ic16-x: 250px;
$ic16-y: 187px;
$ic16-offset-x: -250px;
$ic16-offset-y: -187px;
$ic16-width: 22px;
$ic16-height: 22px;
$ic16-total-width: 460px;
$ic16-total-height: 287px;
$ic16-image: '../images/sprite.png?v=1514188540';
$ic16: (250px, 187px, -250px, -187px, 22px, 22px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic16', );
$ic17-name: 'ic17';
$ic17-x: 334px;
$ic17-y: 151px;
$ic17-offset-x: -334px;
$ic17-offset-y: -151px;
$ic17-width: 12px;
$ic17-height: 9px;
$ic17-total-width: 460px;
$ic17-total-height: 287px;
$ic17-image: '../images/sprite.png?v=1514188540';
$ic17: (334px, 151px, -334px, -151px, 12px, 9px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic17', );
$ic18-name: 'ic18';
$ic18-x: 425px;
$ic18-y: 161px;
$ic18-offset-x: -425px;
$ic18-offset-y: -161px;
$ic18-width: 17px;
$ic18-height: 17px;
$ic18-total-width: 460px;
$ic18-total-height: 287px;
$ic18-image: '../images/sprite.png?v=1514188540';
$ic18: (425px, 161px, -425px, -161px, 17px, 17px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic18', );
$ic19-name: 'ic19';
$ic19-x: 413px;
$ic19-y: 187px;
$ic19-offset-x: -413px;
$ic19-offset-y: -187px;
$ic19-width: 15px;
$ic19-height: 15px;
$ic19-total-width: 460px;
$ic19-total-height: 287px;
$ic19-image: '../images/sprite.png?v=1514188540';
$ic19: (413px, 187px, -413px, -187px, 15px, 15px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic19', );
$ic2-name: 'ic2';
$ic2-x: 250px;
$ic2-y: 161px;
$ic2-offset-x: -250px;
$ic2-offset-y: -161px;
$ic2-width: 24px;
$ic2-height: 26px;
$ic2-total-width: 460px;
$ic2-total-height: 287px;
$ic2-image: '../images/sprite.png?v=1514188540';
$ic2: (250px, 161px, -250px, -161px, 24px, 26px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic2', );
$ic20-name: 'ic20';
$ic20-x: 443px;
$ic20-y: 187px;
$ic20-offset-x: -443px;
$ic20-offset-y: -187px;
$ic20-width: 15px;
$ic20-height: 15px;
$ic20-total-width: 460px;
$ic20-total-height: 287px;
$ic20-image: '../images/sprite.png?v=1514188540';
$ic20: (443px, 187px, -443px, -187px, 15px, 15px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic20', );
$ic21-name: 'ic21';
$ic21-x: 315px;
$ic21-y: 187px;
$ic21-offset-x: -315px;
$ic21-offset-y: -187px;
$ic21-width: 22px;
$ic21-height: 20px;
$ic21-total-width: 460px;
$ic21-total-height: 287px;
$ic21-image: '../images/sprite.png?v=1514188540';
$ic21: (315px, 187px, -315px, -187px, 22px, 20px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic21', );
$ic22-name: 'ic22';
$ic22-x: 294px;
$ic22-y: 187px;
$ic22-offset-x: -294px;
$ic22-offset-y: -187px;
$ic22-width: 21px;
$ic22-height: 21px;
$ic22-total-width: 460px;
$ic22-total-height: 287px;
$ic22-image: '../images/sprite.png?v=1514188540';
$ic22: (294px, 187px, -294px, -187px, 21px, 21px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic22', );
$ic23-name: 'ic23';
$ic23-x: 378px;
$ic23-y: 121px;
$ic23-offset-x: -378px;
$ic23-offset-y: -121px;
$ic23-width: 26px;
$ic23-height: 24px;
$ic23-total-width: 460px;
$ic23-total-height: 287px;
$ic23-image: '../images/sprite.png?v=1514188540';
$ic23: (378px, 121px, -378px, -121px, 26px, 24px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic23', );
$ic24-name: 'ic24';
$ic24-x: 346px;
$ic24-y: 151px;
$ic24-offset-x: -346px;
$ic24-offset-y: -151px;
$ic24-width: 9px;
$ic24-height: 9px;
$ic24-total-width: 460px;
$ic24-total-height: 287px;
$ic24-image: '../images/sprite.png?v=1514188540';
$ic24: (346px, 151px, -346px, -151px, 9px, 9px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic24', );
$ic25-name: 'ic25';
$ic25-x: 428px;
$ic25-y: 187px;
$ic25-offset-x: -428px;
$ic25-offset-y: -187px;
$ic25-width: 15px;
$ic25-height: 15px;
$ic25-total-width: 460px;
$ic25-total-height: 287px;
$ic25-image: '../images/sprite.png?v=1514188540';
$ic25: (428px, 187px, -428px, -187px, 15px, 15px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic25', );
$ic26-name: 'ic26';
$ic26-x: 401px;
$ic26-y: 161px;
$ic26-offset-x: -401px;
$ic26-offset-y: -161px;
$ic26-width: 24px;
$ic26-height: 17px;
$ic26-total-width: 460px;
$ic26-total-height: 287px;
$ic26-image: '../images/sprite.png?v=1514188540';
$ic26: (401px, 161px, -401px, -161px, 24px, 17px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic26', );
$ic27-name: 'ic27';
$ic27-x: 298px;
$ic27-y: 161px;
$ic27-offset-x: -298px;
$ic27-offset-y: -161px;
$ic27-width: 24px;
$ic27-height: 22px;
$ic27-total-width: 460px;
$ic27-total-height: 287px;
$ic27-image: '../images/sprite.png?v=1514188540';
$ic27: (298px, 161px, -298px, -161px, 24px, 22px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic27', );
$ic28-name: 'ic28';
$ic28-x: 268px;
$ic28-y: 209px;
$ic28-offset-x: -268px;
$ic28-offset-y: -209px;
$ic28-width: 16px;
$ic28-height: 22px;
$ic28-total-width: 460px;
$ic28-total-height: 287px;
$ic28-image: '../images/sprite.png?v=1514188540';
$ic28: (268px, 209px, -268px, -209px, 16px, 22px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic28', );
$ic29-name: 'ic29';
$ic29-x: 355px;
$ic29-y: 151px;
$ic29-offset-x: -355px;
$ic29-offset-y: -151px;
$ic29-width: 8px;
$ic29-height: 8px;
$ic29-total-width: 460px;
$ic29-total-height: 287px;
$ic29-image: '../images/sprite.png?v=1514188540';
$ic29: (355px, 151px, -355px, -151px, 8px, 8px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic29', );
$ic3-name: 'ic3';
$ic3-x: 353px;
$ic3-y: 121px;
$ic3-offset-x: -353px;
$ic3-offset-y: -121px;
$ic3-width: 25px;
$ic3-height: 25px;
$ic3-total-width: 460px;
$ic3-total-height: 287px;
$ic3-image: '../images/sprite.png?v=1514188540';
$ic3: (353px, 121px, -353px, -121px, 25px, 25px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic3', );
$ic30-name: 'ic30';
$ic30-x: 322px;
$ic30-y: 151px;
$ic30-offset-x: -322px;
$ic30-offset-y: -151px;
$ic30-width: 12px;
$ic30-height: 9px;
$ic30-total-width: 460px;
$ic30-total-height: 287px;
$ic30-image: '../images/sprite.png?v=1514188540';
$ic30: (322px, 151px, -322px, -151px, 12px, 9px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic30', );
$ic31-w-name: 'ic31-w';
$ic31-w-x: 331px;
$ic31-w-y: 209px;
$ic31-w-offset-x: -331px;
$ic31-w-offset-y: -209px;
$ic31-w-width: 17px;
$ic31-w-height: 13px;
$ic31-w-total-width: 460px;
$ic31-w-total-height: 287px;
$ic31-w-image: '../images/sprite.png?v=1514188540';
$ic31-w: (331px, 209px, -331px, -209px, 17px, 13px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic31-w', );
$ic31-name: 'ic31';
$ic31-x: 348px;
$ic31-y: 209px;
$ic31-offset-x: -348px;
$ic31-offset-y: -209px;
$ic31-width: 17px;
$ic31-height: 13px;
$ic31-total-width: 460px;
$ic31-total-height: 287px;
$ic31-image: '../images/sprite.png?v=1514188540';
$ic31: (348px, 209px, -348px, -209px, 17px, 13px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic31', );
$ic32-w-name: 'ic32-w';
$ic32-w-x: 299px;
$ic32-w-y: 209px;
$ic32-w-offset-x: -299px;
$ic32-w-offset-y: -209px;
$ic32-w-width: 16px;
$ic32-w-height: 16px;
$ic32-w-total-width: 460px;
$ic32-w-total-height: 287px;
$ic32-w-image: '../images/sprite.png?v=1514188540';
$ic32-w: (299px, 209px, -299px, -209px, 16px, 16px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic32-w', );
$ic32-name: 'ic32';
$ic32-x: 315px;
$ic32-y: 209px;
$ic32-offset-x: -315px;
$ic32-offset-y: -209px;
$ic32-width: 16px;
$ic32-height: 16px;
$ic32-total-width: 460px;
$ic32-total-height: 287px;
$ic32-image: '../images/sprite.png?v=1514188540';
$ic32: (315px, 209px, -315px, -209px, 16px, 16px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic32', );
$ic33-name: 'ic33';
$ic33-x: 394px;
$ic33-y: 187px;
$ic33-offset-x: -394px;
$ic33-offset-y: -187px;
$ic33-width: 19px;
$ic33-height: 15px;
$ic33-total-width: 460px;
$ic33-total-height: 287px;
$ic33-image: '../images/sprite.png?v=1514188540';
$ic33: (394px, 187px, -394px, -187px, 19px, 15px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic33', );
$ic34-name: 'ic34';
$ic34-x: 377px;
$ic34-y: 187px;
$ic34-offset-x: -377px;
$ic34-offset-y: -187px;
$ic34-width: 17px;
$ic34-height: 19px;
$ic34-total-width: 460px;
$ic34-total-height: 287px;
$ic34-image: '../images/sprite.png?v=1514188540';
$ic34: (377px, 187px, -377px, -187px, 17px, 19px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic34', );
$ic35-name: 'ic35';
$ic35-x: 290px;
$ic35-y: 151px;
$ic35-offset-x: -290px;
$ic35-offset-y: -151px;
$ic35-width: 32px;
$ic35-height: 9px;
$ic35-total-width: 460px;
$ic35-total-height: 287px;
$ic35-image: '../images/sprite.png?v=1514188540';
$ic35: (290px, 151px, -290px, -151px, 32px, 9px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic35', );
$ic36-name: 'ic36';
$ic36-x: 0px;
$ic36-y: 242px;
$ic36-offset-x: 0px;
$ic36-offset-y: -242px;
$ic36-width: 9px;
$ic36-height: 45px;
$ic36-total-width: 460px;
$ic36-total-height: 287px;
$ic36-image: '../images/sprite.png?v=1514188540';
$ic36: (0px, 242px, 0px, -242px, 9px, 45px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic36', );
$ic4-name: 'ic4';
$ic4-x: 374px;
$ic4-y: 161px;
$ic4-offset-x: -374px;
$ic4-offset-y: -161px;
$ic4-width: 27px;
$ic4-height: 18px;
$ic4-total-width: 460px;
$ic4-total-height: 287px;
$ic4-image: '../images/sprite.png?v=1514188540';
$ic4: (374px, 161px, -374px, -161px, 27px, 18px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic4', );
$ic5-name: 'ic5';
$ic5-x: 320px;
$ic5-y: 121px;
$ic5-offset-x: -320px;
$ic5-offset-y: -121px;
$ic5-width: 33px;
$ic5-height: 26px;
$ic5-total-width: 460px;
$ic5-total-height: 287px;
$ic5-image: '../images/sprite.png?v=1514188540';
$ic5: (320px, 121px, -320px, -121px, 33px, 26px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic5', );
$ic6-name: 'ic6';
$ic6-x: 250px;
$ic6-y: 209px;
$ic6-offset-x: -250px;
$ic6-offset-y: -209px;
$ic6-width: 18px;
$ic6-height: 22px;
$ic6-total-width: 460px;
$ic6-total-height: 287px;
$ic6-image: '../images/sprite.png?v=1514188540';
$ic6: (250px, 209px, -250px, -209px, 18px, 22px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic6', );
$ic7-name: 'ic7';
$ic7-x: 348px;
$ic7-y: 161px;
$ic7-offset-x: -348px;
$ic7-offset-y: -161px;
$ic7-width: 26px;
$ic7-height: 20px;
$ic7-total-width: 460px;
$ic7-total-height: 287px;
$ic7-image: '../images/sprite.png?v=1514188540';
$ic7: (348px, 161px, -348px, -161px, 26px, 20px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic7', );
$ic8-name: 'ic8';
$ic8-x: 322px;
$ic8-y: 161px;
$ic8-offset-x: -322px;
$ic8-offset-y: -161px;
$ic8-width: 26px;
$ic8-height: 20px;
$ic8-total-width: 460px;
$ic8-total-height: 287px;
$ic8-image: '../images/sprite.png?v=1514188540';
$ic8: (322px, 161px, -322px, -161px, 26px, 20px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic8', );
$ic9-name: 'ic9';
$ic9-x: 337px;
$ic9-y: 187px;
$ic9-offset-x: -337px;
$ic9-offset-y: -187px;
$ic9-width: 20px;
$ic9-height: 20px;
$ic9-total-width: 460px;
$ic9-total-height: 287px;
$ic9-image: '../images/sprite.png?v=1514188540';
$ic9: (337px, 187px, -337px, -187px, 20px, 20px, 460px, 287px, '../images/sprite.png?v=1514188540', 'ic9', );
$logo-name: 'logo';
$logo-x: 0px;
$logo-y: 121px;
$logo-offset-x: 0px;
$logo-offset-y: -121px;
$logo-width: 250px;
$logo-height: 121px;
$logo-total-width: 460px;
$logo-total-height: 287px;
$logo-image: '../images/sprite.png?v=1514188540';
$logo: (0px, 121px, 0px, -121px, 250px, 121px, 460px, 287px, '../images/sprite.png?v=1514188540', 'logo', );
$logo2-name: 'logo2';
$logo2-x: 0px;
$logo2-y: 0px;
$logo2-offset-x: 0px;
$logo2-offset-y: 0px;
$logo2-width: 460px;
$logo2-height: 121px;
$logo2-total-width: 460px;
$logo2-total-height: 287px;
$logo2-image: '../images/sprite.png?v=1514188540';
$logo2: (0px, 0px, 0px, 0px, 460px, 121px, 460px, 287px, '../images/sprite.png?v=1514188540', 'logo2', );
$task-complete-name: 'task-complete';
$task-complete-x: 290px;
$task-complete-y: 121px;
$task-complete-offset-x: -290px;
$task-complete-offset-y: -121px;
$task-complete-width: 30px;
$task-complete-height: 30px;
$task-complete-total-width: 460px;
$task-complete-total-height: 287px;
$task-complete-image: '../images/sprite.png?v=1514188540';
$task-complete: (290px, 121px, -290px, -121px, 30px, 30px, 460px, 287px, '../images/sprite.png?v=1514188540', 'task-complete', );
$spritesheet-width: 460px;
$spritesheet-height: 287px;
$spritesheet-image: '../images/sprite.png?v=1514188540';
$spritesheet-sprites: ($ava, $ic1, $ic10, $ic11, $ic12, $ic13, $ic14, $ic15, $ic16, $ic17, $ic18, $ic19, $ic2, $ic20, $ic21, $ic22, $ic23, $ic24, $ic25, $ic26, $ic27, $ic28, $ic29, $ic3, $ic30, $ic31-w, $ic31, $ic32-w, $ic32, $ic33, $ic34, $ic35, $ic36, $ic4, $ic5, $ic6, $ic7, $ic8, $ic9, $logo, $logo2, $task-complete, );
$spritesheet: (460px, 287px, '../images/sprite.png?v=1514188540', $spritesheet-sprites, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
